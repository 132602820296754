import { __assign } from "tslib";
import postMessageToIframe from './iframes/postMessageToIframe';
import { ENCRYPTED_EXPIRY_DATE, ENCRYPTED_EXPIRY_MONTH, ENCRYPTED_EXPIRY_YEAR } from '../../configuration/constants';
export function processAutoComplete(pFeedbackObj) {
    var _this = this;
    // Specifically for cc-name (but no reason not to propagate all AC objects to the merchant)
    if (pFeedbackObj.name === 'cc-name') {
        var feedbackObj = __assign({}, pFeedbackObj);
        delete feedbackObj.numKey;
        var ACFeedbackObj = feedbackObj;
        this.callbacks.onAutoComplete(ACFeedbackObj);
    }
    // Send date info to relevant secured fields
    if (pFeedbackObj.name === 'cc-exp') {
        var dateValArr = pFeedbackObj.value.split('/');
        if (dateValArr.length !== 2)
            return; // To avoid bug in some versions of Safari where date doesn't come through as expected
        if (dateValArr[0].length === 1)
            dateValArr[0] = "0" + dateValArr[0]; // pad, if required
        var acMonthVal = dateValArr[0];
        var acYearVal_1 = dateValArr[1].substr(2); // take last 2 digits of year
        var acDateVal = acMonthVal + "/" + acYearVal_1;
        if (Object.prototype.hasOwnProperty.call(this.state.securedFields, ENCRYPTED_EXPIRY_DATE)) {
            var dataObj = {
                txVariant: this.state.type,
                fieldType: ENCRYPTED_EXPIRY_DATE,
                autoComplete: acDateVal,
                numKey: this.state.securedFields[ENCRYPTED_EXPIRY_DATE].numKey
            };
            postMessageToIframe(dataObj, this.getIframeContentWin(ENCRYPTED_EXPIRY_DATE), this.config.loadingContext);
            return;
        }
        if (Object.prototype.hasOwnProperty.call(this.state.securedFields, ENCRYPTED_EXPIRY_MONTH)) {
            var dataObj = {
                txVariant: this.state.type,
                fieldType: ENCRYPTED_EXPIRY_MONTH,
                autoComplete: acMonthVal,
                numKey: this.state.securedFields[ENCRYPTED_EXPIRY_MONTH].numKey
            };
            postMessageToIframe(dataObj, this.getIframeContentWin(ENCRYPTED_EXPIRY_MONTH), this.config.loadingContext);
        }
        if (Object.prototype.hasOwnProperty.call(this.state.securedFields, ENCRYPTED_EXPIRY_YEAR)) {
            // Dirty! - Need to wait til next page draw if setting month and year at the same time, otherwise only year gets set
            setTimeout(function () {
                var dataObj = {
                    txVariant: _this.state.type,
                    fieldType: ENCRYPTED_EXPIRY_YEAR,
                    autoComplete: acYearVal_1,
                    numKey: _this.state.securedFields[ENCRYPTED_EXPIRY_YEAR].numKey
                };
                postMessageToIframe(dataObj, _this.getIframeContentWin(ENCRYPTED_EXPIRY_YEAR), _this.config.loadingContext);
            }, 0);
        }
    }
}
