import { __extends } from "tslib";
import { handleConfig } from '../configuration/handleConfig';
import { configureCallbacks } from '../configuration/configureCallbacks';
import { handleProcessBrand } from './utils/processBrand';
import { handleValidation } from './handleValidation';
import { handleEncryption } from './handleEncryption';
import { createSecuredFields, createNonCardSecuredFields, createCardSecuredFields, setupSecuredField } from './createSecuredFields';
import { setFocusOnFrame } from './utils/iframes/setFocusOnFrame';
import { postMessageToAllIframes } from './utils/iframes/postMessageToAllIframes';
import { destroySecuredFields } from './destroySecuredFields';
import { processAutoComplete } from './utils/processAutoComplete';
import { handleFocus } from './utils/iframes/handleFocus';
import { handleIframeConfigFeedback } from './utils/iframes/handleIframeConfigFeedback';
import { isConfigured } from './utils/isConfigured';
import { assessFormValidity } from './utils/validateForm';
import { handleBinValue } from './utils/handleBinValue';
import { handleBrandFromBinLookup, sendBrandToCardSF } from './utils/handleBrandFromBinLookup';
import handleAdditionalFields from './utils/registerAdditionalField';
import tabHandlers from './utils/tabbing/handleTab';
import postMessageToIframe from './utils/iframes/postMessageToIframe';
import AbstractCSF from './AbstractCSF';
import * as logger from '../utilities/logger';
var notConfiguredWarning = function (str) {
    if (str === void 0) { str = 'You cannot use secured fields'; }
    logger.warn(str + " - they are not yet configured. Use the 'onConfigSuccess' callback to know when this has happened.");
};
var CSF = /** @class */ (function (_super) {
    __extends(CSF, _super);
    // --
    function CSF(setupObj) {
        var _this = _super.call(this, setupObj) || this;
        _this.state = {
            /**
             * @type
             *  For generic card will always be 'card'.
             *  For non-generic card will be hardcoded to a particular txVariant e.g. 'mc' or 'visa'
             */
            type: _this.props.type,
            /**
             * @brand
             *  For generic card will change as shopper types
             *  For non-generic card will be fixed
             */
            brand: _this.props.type !== 'card' ? _this.props.type : null,
            allValid: undefined,
            numIframes: 0,
            iframeCount: 0,
            iframeConfigCount: 0,
            isConfigured: false,
            hasSeparateDateFields: false,
            currentFocusObject: null,
            registerFieldForIos: false,
            securedFields: {}
        };
        // Setup 'this' references
        _this.configHandler = handleConfig;
        _this.callbacksHandler = configureCallbacks;
        _this.handleIframeConfigFeedback = handleIframeConfigFeedback;
        _this.isConfigured = isConfigured;
        _this.assessFormValidity = assessFormValidity;
        _this.processBrand = handleProcessBrand;
        _this.handleValidation = handleValidation;
        _this.handleEncryption = handleEncryption;
        _this.createSecuredFields = createSecuredFields;
        _this.createNonCardSecuredFields = createNonCardSecuredFields;
        _this.createCardSecuredFields = createCardSecuredFields;
        _this.setupSecuredField = setupSecuredField;
        _this.postMessageToAllIframes = postMessageToAllIframes;
        _this.setFocusOnFrame = setFocusOnFrame;
        _this.handleFocus = handleFocus;
        _this.handleAdditionalFields = handleAdditionalFields.handleAdditionalFields;
        _this.touchendListener = handleAdditionalFields.touchendListener.bind(_this);
        _this.destroyTouchendListener = handleAdditionalFields.destroyTouchendListener;
        _this.handleSFShiftTab = tabHandlers.handleSFShiftTab;
        _this.handleShiftTab = tabHandlers.handleShiftTab;
        _this.destroySecuredFields = destroySecuredFields;
        _this.processAutoComplete = processAutoComplete;
        _this.handleBinValue = handleBinValue;
        _this.brandsFromBinLookup = handleBrandFromBinLookup;
        _this.sendBrandToCardSF = sendBrandToCardSF;
        // Populate config & callbacks objects & create securedFields
        _this.init();
        return _this;
    }
    CSF.prototype.init = function () {
        this.configHandler();
        this.callbacksHandler(this.props.callbacks);
        /**
         * Create all the securedFields
         */
        var numIframes = this.createSecuredFields();
        this.state.numIframes = numIframes;
    };
    // Expose functions that can be called on the CSF instance
    CSF.prototype.createReturnObject = function () {
        var _this = this;
        // --
        var returnObj = {
            // --
            updateStyles: function (pStyleObject) {
                if (_this.state.isConfigured) {
                    _this.postMessageToAllIframes({ styleObject: pStyleObject });
                }
                else {
                    logger.warn('You cannot update the secured fields styling ' +
                        "- they are not yet configured. Use the 'onConfigSuccess' callback to know when this has happened.");
                }
                return returnObj;
            },
            setFocusOnFrame: function (pFieldType) {
                if (_this.state.isConfigured) {
                    _this.setFocusOnFrame(pFieldType);
                }
                else {
                    notConfiguredWarning('You cannot set focus on any secured field');
                }
                return returnObj;
            },
            // For component based implementation - if showValidation function is called on the component use this
            // function as a way to notify the CSF that a field is in error
            isValidated: function (pFieldType) {
                if (_this.state.isConfigured) {
                    if (Object.prototype.hasOwnProperty.call(_this.state.securedFields, pFieldType)) {
                        _this.state.securedFields[pFieldType].hasError = true;
                        // If there's not already an errorType, set one
                        // NOTE: fixes issue in Components where you first validate and then start typing a maestro number
                        // - w/o this and the corresponding fix in the SecuredField class the maestro PM will never register as valid
                        if (_this.state.securedFields[pFieldType].errorType === '') {
                            _this.state.securedFields[pFieldType].errorType = 'isValidated';
                        }
                        // Inform iframe
                        var dataObj = {
                            txVariant: _this.state.type,
                            fieldType: pFieldType,
                            externalValidation: true,
                            numKey: _this.state.securedFields[pFieldType].numKey
                        };
                        postMessageToIframe(dataObj, _this.getIframeContentWin(pFieldType), _this.config.loadingContext);
                    }
                }
                else {
                    notConfiguredWarning('You cannot set validated on any secured field');
                }
                return returnObj;
            },
            destroy: function () {
                if (_this.state.isConfigured) {
                    _this.destroySecuredFields();
                }
                else {
                    notConfiguredWarning('You cannot destroy secured fields');
                }
                return returnObj;
            },
            brandsFromBinLookup: function (brandsObj) {
                if (!_this.config.isCreditCardType)
                    return null;
                if (_this.state.isConfigured) {
                    _this.brandsFromBinLookup(brandsObj);
                }
                else {
                    notConfiguredWarning('You cannot set pass brands to secured fields');
                }
                return returnObj;
            }
        };
        return returnObj;
    };
    /**
     * Retrieves the iframe, stored by field type, & returns it's contentWindow
     */
    CSF.prototype.getIframeContentWin = function (fieldType) {
        return this.state.securedFields[fieldType].iframeContentWindow || null;
    };
    return CSF;
}(AbstractCSF));
export default CSF;
