import { __assign, __extends } from "tslib";
import { Component, h } from 'preact';
import useCoreContext from '~/core/Context/useCoreContext';
import { renderFormField } from '~/components/internal/FormFields';
import Field from '~/components/internal/FormFields/Field';
import { isValidHolder, checkIbanStatus } from './validate';
import { electronicFormat, formatIban, getIbanPlaceHolder, getNextCursorPosition } from './utils';
import './IbanInput.scss';
var IbanInput = /** @class */ (function (_super) {
    __extends(IbanInput, _super);
    function IbanInput(props) {
        var _this = _super.call(this, props) || this;
        _this.setData = function (key, value, cb) {
            _this.setState(function (prevState) {
                var _a;
                return ({ data: __assign(__assign({}, prevState.data), (_a = {}, _a[key] = value, _a)) });
            }, cb);
        };
        _this.setError = function (key, value, cb) {
            _this.setState(function (prevState) {
                var _a;
                return ({ errors: __assign(__assign({}, prevState.errors), (_a = {}, _a[key] = value, _a)) });
            }, cb);
        };
        _this.setValid = function (key, value, cb) {
            _this.setState(function (prevState) {
                var _a;
                return ({ valid: __assign(__assign({}, prevState.valid), (_a = {}, _a[key] = value, _a)) });
            }, cb);
        };
        _this.handleHolderInput = function (holder) {
            _this.setState(function (prevState) { return ({ data: __assign(__assign({}, prevState.data), { 'sepa.ownerName': holder }) }); }, function () {
                _this.setError('holder', !isValidHolder(_this.state.data['sepa.ownerName']));
                _this.onChange(); // propagate state
            });
        };
        _this.handleIbanInput = function (e) {
            var inputValue = e.target.value;
            var electronicFormatIban = electronicFormat(inputValue); // example: NL13TEST0123456789
            var iban = formatIban(electronicFormatIban); // example: NL13 TEST 0123 4567 89
            var validationStatus = checkIbanStatus(iban).status;
            // calculate cursor's new position
            var cursor = e.target.selectionStart;
            var previousIban = _this.state.data['sepa.ibanNumber'];
            var newCursorPosition = getNextCursorPosition(cursor, iban, previousIban);
            _this.setState(function (prevState) { return ({
                data: __assign(__assign({}, prevState.data), { 'sepa.ibanNumber': iban }),
                errors: __assign(__assign({}, prevState.errors), { iban: validationStatus === 'invalid' ? 'sepaDirectDebit.ibanField.invalid' : null }),
                valid: __assign(__assign({}, prevState.valid), { iban: validationStatus === 'valid' })
            }); }, function () {
                e.target.setSelectionRange(newCursorPosition, newCursorPosition);
                _this.onChange();
            });
        };
        _this.handleIbanBlur = function (e) {
            var currentIban = e.target.value;
            if (currentIban.length > 0) {
                var validationStatus = checkIbanStatus(currentIban).status;
                _this.setError('iban', validationStatus !== 'valid' ? 'sepaDirectDebit.ibanField.invalid' : null);
            }
        };
        _this.state = {
            status: 'ready',
            data: {
                'sepa.ownerName': '',
                'sepa.ibanNumber': ''
            },
            isValid: false,
            cursor: 0,
            errors: {},
            valid: {}
        };
        _this.ibanNumber = {};
        return _this;
    }
    IbanInput.prototype.setStatus = function (status) {
        this.setState({ status: status });
    };
    IbanInput.prototype.onChange = function () {
        var holderNameValid = this.props.holderName ? isValidHolder(this.state.data['sepa.ownerName']) : '';
        var ibanValid = checkIbanStatus(this.state.data['sepa.ibanNumber']).status === 'valid';
        var isValid = ibanValid && holderNameValid;
        var data = { data: this.state.data, isValid: isValid };
        this.props.onChange(data);
    };
    IbanInput.prototype.showValidation = function () {
        var validationStatus = checkIbanStatus(this.state.data['sepa.ibanNumber']).status;
        var holderStatus = isValidHolder(this.state.data['sepa.ownerName']);
        this.setError('iban', validationStatus !== 'valid' ? 'sepaDirectDebit.ibanField.invalid' : null);
        this.setError('holder', !holderStatus ? true : null);
    };
    IbanInput.prototype.render = function (_a, _b) {
        var _this = this;
        var placeholders = _a.placeholders, countryCode = _a.countryCode;
        var data = _b.data, errors = _b.errors, valid = _b.valid;
        var i18n = useCoreContext().i18n;
        return (h("div", { className: "adyen-checkout__iban-input" },
            this.props.holderName && (h(Field, { className: 'adyen-checkout__field--owner-name', label: i18n.get('sepa.ownerName'), filled: data['sepa.ownerName'] && data['sepa.ownerName'].length, errorMessage: errors.holder ? i18n.get('creditCard.holderName.invalid') : false }, renderFormField('text', {
                name: 'sepa.ownerName',
                className: 'adyen-checkout__iban-input__owner-name',
                placeholder: 'ownerName' in placeholders ? placeholders.ownerName : i18n.get('sepaDirectDebit.nameField.placeholder'),
                value: data['sepa.ownerName'],
                'aria-invalid': !!this.state.errors.holder,
                'aria-label': i18n.get('sepa.ownerName'),
                onInput: function (e) { return _this.handleHolderInput(e.target.value); }
            }))),
            h(Field, { className: 'adyen-checkout__field--iban-number', label: i18n.get('sepa.ibanNumber'), errorMessage: errors.iban ? i18n.get(errors.iban) : false, filled: data['sepa.ibanNumber'] && data['sepa.ibanNumber'].length, isValid: valid.iban, onBlur: this.handleIbanBlur }, renderFormField('text', {
                ref: function (ref) {
                    _this.ibanNumber = ref;
                },
                name: 'sepa.ibanNumber',
                className: 'adyen-checkout__iban-input__iban-number',
                classNameModifiers: ['large'],
                placeholder: 'ibanNumber' in placeholders ? placeholders.ibanNumber : getIbanPlaceHolder(countryCode),
                value: data['sepa.ibanNumber'],
                onInput: this.handleIbanInput,
                'aria-invalid': !!this.state.errors.iban,
                'aria-label': i18n.get('sepa.ibanNumber'),
                autocorrect: 'off',
                spellcheck: false
            })),
            this.props.showPayButton && this.props.payButton({ status: this.state.status })));
    };
    IbanInput.defaultProps = {
        onChange: function () { },
        countryCode: null,
        holderName: true,
        placeholders: {}
    };
    return IbanInput;
}(Component));
export default IbanInput;
