import { __assign, __rest } from "tslib";
import { h, Fragment } from 'preact';
import getLinkUrl from './getLinkUrl';
import Field from '~/components/internal/FormFields/Field';
import Checkbox from '~/components/internal/FormFields/Checkbox';
function ConsentCheckboxLabel(_a) {
    var countryCode = _a.countryCode, i18n = _a.i18n;
    var languageCode = i18n.locale.toLowerCase().slice(0, 2);
    var linkUrl = getLinkUrl(countryCode, languageCode);
    var linkText = i18n.get('paymentConditions');
    var translationString = i18n.get('afterPay.agreement');
    var _b = translationString.split('%@'), textBeforeLink = _b[0], textAfterLink = _b[1];
    if (textBeforeLink && textAfterLink) {
        return (h(Fragment, null,
            textBeforeLink,
            h("a", { className: "adyen-checkout__link", target: "_blank", rel: "noopener noreferrer", href: linkUrl }, linkText),
            textAfterLink));
    }
    return h("span", { className: "adyen-checkout__checkbox__label" }, i18n.get('privacyPolicy'));
}
export default function ConsentCheckbox(_a) {
    var data = _a.data, errorMessage = _a.errorMessage, onChange = _a.onChange, props = __rest(_a, ["data", "errorMessage", "onChange"]);
    return (h(Field, { classNameModifiers: ['consentCheckbox'], errorMessage: errorMessage },
        h(Checkbox, __assign({}, props, { name: "consentCheckbox", classNameModifiers: ['consentCheckbox'], onInput: onChange, value: data.consentCheckbox, label: h(ConsentCheckboxLabel, { countryCode: props.countryCode, i18n: props.i18n }) }))));
}
