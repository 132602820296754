import { h } from 'preact';
import useCoreContext from '~/core/Context/useCoreContext';
import getImageUrl from '~/utils/get-image';
var Icon = function (_a) {
    var type = _a.type;
    var loadingContext = useCoreContext().loadingContext;
    var iconUrl = getImageUrl({ loadingContext: loadingContext, imageFolder: 'components/' })(type);
    return h("img", { className: "adyen-checkout__icon", alt: type, src: iconUrl });
};
export default Icon;
