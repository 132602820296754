import { __assign, __rest } from "tslib";
import { h, Fragment } from 'preact';
import useCoreContext from '~/core/Context/useCoreContext';
import { useState } from 'preact/hooks';
function RedirectButton(_a) {
    var payButton = _a.payButton, onSubmit = _a.onSubmit, _b = _a.amount, amount = _b === void 0 ? null : _b, name = _a.name, props = __rest(_a, ["payButton", "onSubmit", "amount", "name"]);
    var i18n = useCoreContext().i18n;
    var _c = useState('ready'), status = _c[0], setStatus = _c[1];
    this.setStatus = function (newStatus) {
        setStatus(newStatus);
    };
    var payButtonLabel = function () {
        var isZeroAuth = amount && {}.hasOwnProperty.call(amount, 'value') && amount.value === 0;
        if (isZeroAuth)
            return i18n.get('preauthorizeWith') + " " + name;
        return i18n.get('continueTo') + " " + name;
    };
    return (h(Fragment, null, payButton(__assign(__assign({}, props), { status: status, classNameModifiers: ['standalone'], label: payButtonLabel(), onClick: onSubmit }))));
}
export default RedirectButton;
