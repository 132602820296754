import { __assign } from "tslib";
import { filterAllowedPaymentMethods, filterRemovedPaymentMethods, filterEcomStoredPaymentMethods, filterSupportedStoredPaymentMethods } from './filters';
function processStoredPaymentMethod(pm) {
    return __assign(__assign({}, pm), { storedPaymentMethodId: pm.id });
}
export var processPaymentMethods = function (paymentMethodsResponse, _a) {
    var _b = _a.allowPaymentMethods, allowPaymentMethods = _b === void 0 ? [] : _b, _c = _a.removePaymentMethods, removePaymentMethods = _c === void 0 ? [] : _c;
    var _d = paymentMethodsResponse.paymentMethods, paymentMethods = _d === void 0 ? [] : _d;
    return paymentMethods.filter(filterAllowedPaymentMethods, allowPaymentMethods).filter(filterRemovedPaymentMethods, removePaymentMethods);
};
export var processStoredPaymentMethods = function (paymentMethodsResponse, _a) {
    if (paymentMethodsResponse === void 0) { paymentMethodsResponse = {}; }
    var _b = _a.allowPaymentMethods, allowPaymentMethods = _b === void 0 ? [] : _b, _c = _a.removePaymentMethods, removePaymentMethods = _c === void 0 ? [] : _c;
    var _d = paymentMethodsResponse.storedPaymentMethods, storedPaymentMethods = _d === void 0 ? [] : _d;
    return storedPaymentMethods
        .filter(filterSupportedStoredPaymentMethods) // only display supported stored payment methods
        .filter(filterAllowedPaymentMethods, allowPaymentMethods)
        .filter(filterRemovedPaymentMethods, removePaymentMethods)
        .filter(filterEcomStoredPaymentMethods) // Only accept Ecommerce shopper interactions
        .map(processStoredPaymentMethod);
};
var PaymentMethodsResponse = /** @class */ (function () {
    function PaymentMethodsResponse(response, options) {
        if (options === void 0) { options = {}; }
        this.paymentMethods = [];
        this.storedPaymentMethods = [];
        if (typeof response === 'string') {
            throw new Error("paymentMethodsResponse was provided but of an incorrect type (should be an object but a string was provided).\n                Try JSON.parse(\"{...}\") your paymentMethodsResponse.");
        }
        this.paymentMethods = response ? processPaymentMethods(response, options) : [];
        this.storedPaymentMethods = response ? processStoredPaymentMethods(response, options) : [];
    }
    PaymentMethodsResponse.prototype.has = function (paymentMethod) {
        return Boolean(this.paymentMethods.find(function (pm) { return pm.type === paymentMethod; }));
    };
    PaymentMethodsResponse.prototype.find = function (paymentMethod) {
        return this.paymentMethods.find(function (pm) { return pm.type === paymentMethod; });
    };
    return PaymentMethodsResponse;
}());
export default PaymentMethodsResponse;
