import { __assign, __extends } from "tslib";
import { Component, h } from 'preact';
import InputBase from './InputBase';
var InputText = /** @class */ (function (_super) {
    __extends(InputText, _super);
    function InputText() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InputText.prototype.render = function () {
        return h(InputBase, __assign({ classNameModifiers: ['large'] }, this.props, { type: "text" }));
    };
    InputText.defaultProps = {};
    return InputText;
}(Component));
export default InputText;
