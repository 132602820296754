import { __assign, __extends } from "tslib";
import * as logger from '../utilities/logger';
import createIframe from '../utilities/createIframe';
import { selectOne, on, off, removeAllChildren } from '../utilities/dom';
import postMessageToIframe from './utils/iframes/postMessageToIframe';
import { isWebpackPostMsg, originCheckPassed, isChromeVoxPostMsg } from './utils/iframes/postMessageValidation';
import { ENCRYPTED_SECURITY_CODE, IFRAME_TITLE } from '../configuration/constants';
import { generateRandomNumber } from '../utilities/commonUtils';
import AbstractSecuredField from '~/components/internal/SecuredFields/lib/core/AbstractSecuredField';
import { pick, reject } from '~/components/internal/SecuredFields/utils';
import getProp from '~/utils/getProp';
var logPostMsg = false;
var doLog = false;
var SecuredField = /** @class */ (function (_super) {
    __extends(SecuredField, _super);
    // --
    function SecuredField(pSetupObj) {
        var _this = _super.call(this) || this;
        // List of props from setup object not needed for iframe config
        var deltaPropsArr = ['fieldType', 'cvcRequired', 'iframeSrc', 'loadingContext', 'holderEl'];
        // Copy passed setup object values to this.config
        var configVarsFromSetUpObj = reject(deltaPropsArr).from(pSetupObj);
        _this.config = __assign(__assign({}, _this.config), configVarsFromSetUpObj);
        // Copy passed setup object values to this
        var thisVarsFromSetupObj = pick(deltaPropsArr).from(pSetupObj);
        _this.fieldType = thisVarsFromSetupObj.fieldType;
        _this.cvcRequired = thisVarsFromSetupObj.cvcRequired;
        _this.iframeSrc = thisVarsFromSetupObj.iframeSrc;
        _this.loadingContext = thisVarsFromSetupObj.loadingContext;
        _this.holderEl = thisVarsFromSetupObj.holderEl;
        // Initiate values through setters
        _this.isValid = false;
        _this.iframeContentWindow = null;
        _this.numKey = generateRandomNumber();
        _this.isEncrypted = false;
        _this.hasError = false;
        _this.errorType = '';
        if (process.env.NODE_ENV === 'development' && doLog) {
            logger.log('### SecuredFieldCls::constructor:: this.fieldType=', _this.fieldType, 'isValid=', _this._isValid, 'numKey=', _this.numKey);
            logger.log('\n');
        }
        return _this.init();
    }
    SecuredField.prototype.init = function () {
        var iframeTitle = getProp(this.config, "pmConfig.ariaLabels." + this.fieldType + ".iframeTitle") || IFRAME_TITLE;
        var iframeEl = createIframe("" + this.iframeSrc, iframeTitle);
        // Place the iframe into the holder
        this.holderEl.appendChild(iframeEl);
        // Now examine the holder to get an actual DOM node
        var iframe = selectOne(this.holderEl, '.js-iframe');
        if (iframe) {
            this.iframeContentWindow = iframe.contentWindow;
            // Create reference to bound fn (see getters/setters for binding)
            this.iframeOnLoadListener = this.iframeOnLoadListenerFn;
            on(iframe, 'load', this.iframeOnLoadListener, false);
        }
        return this;
    };
    SecuredField.prototype.iframeOnLoadListenerFn = function () {
        if (process.env.NODE_ENV === 'development' && window._b$dl) {
            logger.log('\n############################');
            logger.log('### SecuredFieldCls:::: onIframeLoaded:: this type=', this.config.txVariant);
        }
        off(window, 'load', this.iframeOnLoadListener, false);
        // Create reference to bound fn (see getters/setters for binding)
        this.postMessageListener = this.postMessageListenerFn;
        // Add general listener for 'message' EVENT - the event that 'powers' postMessage
        on(window, 'message', this.postMessageListener, false);
        // Create and send config object to iframe
        var configObj = {
            fieldType: this.fieldType,
            cvcRequired: this.cvcRequired,
            numKey: this.numKey,
            txVariant: this.config.txVariant,
            extraFieldData: this.config.extraFieldData,
            cardGroupTypes: this.config.cardGroupTypes,
            iframeUIConfig: this.config.iframeUIConfig,
            pmConfig: this.config.iframeUIConfig,
            sfLogAtStart: this.config.sfLogAtStart,
            showWarnings: this.config.showWarnings,
            trimTrailingSeparator: this.config.trimTrailingSeparator,
            isCreditCardType: this.config.isCreditCardType
        };
        if (process.env.NODE_ENV === 'development' && window._b$dl) {
            logger.log('### SecuredFieldCls:::: onIframeLoaded:: created configObj=', configObj);
        }
        postMessageToIframe(configObj, this.iframeContentWindow, this.loadingContext);
        //--
        // Callback to say iframe loaded
        this.onIframeLoadedCallback();
    };
    SecuredField.prototype.postMessageListenerFn = function (event) {
        // Check message is from expected domain
        if (!originCheckPassed(event, this.loadingContext, this.config.showWarnings)) {
            return;
        }
        // TODO - for debugging purposes this would always be useful to see
        //        logger.log('\n',this.fieldType,'### CSF SecuredFieldCls::postMessageListener:: event.data=',event.data);
        if (process.env.NODE_ENV === 'development' && logPostMsg) {
            logger.log('\n###CSF SecuredFieldCls::postMessageListener:: DOMAIN & ORIGIN MATCH, NO WEBPACK WEIRDNESS fieldType=', this.fieldType, 'txVariant=', this.config.txVariant, 'this.numKey=', this.numKey);
        }
        // PARSE DATA OBJECT (thus testing if it is a JSON string) - OR TRY & WORK OUT WHY THE PARSING FAILED
        var feedbackObj;
        try {
            feedbackObj = JSON.parse(event.data);
        }
        catch (e) {
            // Was the message generated by webpack?
            if (isWebpackPostMsg(event)) {
                if (this.config.showWarnings)
                    logger.log('### SecuredFieldCls::postMessageListenerFn:: PARSE FAIL - WEBPACK');
                return;
            }
            // Was the message generated by ChromeVox?
            if (isChromeVoxPostMsg(event)) {
                if (this.config.showWarnings)
                    logger.log('### SecuredFieldCls::postMessageListenerFn:: PARSE FAIL - CHROMEVOX');
                return;
            }
            if (this.config.showWarnings)
                logger.log('### SecuredFieldCls::postMessageListenerFn:: PARSE FAIL - UNKNOWN REASON: event.data=', event.data);
            return;
        }
        // CHECK FOR EXPECTED PROPS
        var hasMainProps = Object.prototype.hasOwnProperty.call(feedbackObj, 'action') && Object.prototype.hasOwnProperty.call(feedbackObj, 'numKey');
        if (!hasMainProps) {
            if (this.config.showWarnings)
                logger.warn('WARNING SecuredFieldCls :: postMessage listener for iframe :: data mismatch!');
            return;
        }
        if (process.env.NODE_ENV === 'development' && logPostMsg) {
            logger.log('### SecuredFieldCls::postMessageListener:: feedbackObj.numKey=', feedbackObj.numKey);
        }
        if (this.numKey !== feedbackObj.numKey) {
            if (this.config.showWarnings) {
                logger.warn('WARNING SecuredFieldCls :: postMessage listener for iframe :: data mismatch! ' +
                    '(Probably a message from an unrelated securedField)');
            }
            return;
        }
        // VALIDATION CHECKS PASSED - DECIDE ON COURSE OF ACTION
        if (process.env.NODE_ENV === 'development' && logPostMsg) {
            logger.log('### SecuredFieldCls::postMessageListener:: numkeys match PROCEED WITH POST MESSAGE PROCESSING fieldType=', this.fieldType, 'txVariant=', this.config.txVariant);
        }
        switch (feedbackObj.action) {
            case 'encryption':
                this.isValid = true;
                this.onEncryptionCallback(feedbackObj);
                break;
            case 'config':
                this.onConfigCallback();
                break;
            case 'focus':
                this.onFocusCallback(feedbackObj);
                break;
            case 'binValue':
                this.onBinValueCallback(feedbackObj);
                break;
            // iOS ONLY - RE. iOS BUGS AROUND BLUR AND FOCUS EVENTS
            case 'click':
                this.onClickCallback(feedbackObj);
                break;
            // Only happens for Firefox & IE <= 11
            case 'shifttab':
                this.onShiftTabCallback(feedbackObj);
                break;
            case 'autoComplete':
                this.onAutoCompleteCallback(feedbackObj);
                break;
            /**
             * Validate, because action=
             *  'numberKeyPressed' or date-, month-, year-, cvc-, pin-, or iban- KeyPressed (i.e. regular, "non-error" event)
             *  'delete'
             *  'luhnCheck'
             *  'brand'
             *  'incomplete field' (follows from a focus (blur) event)
             */
            default:
                // If we're validation handling (& not encryption handling) field must be invalid
                this.isValid = false;
                this.onValidationCallback(feedbackObj);
        }
    };
    SecuredField.prototype.destroy = function () {
        off(window, 'message', this.postMessageListener, false);
        this.iframeContentWindow = null;
        removeAllChildren(this.holderEl);
    };
    // /////// ALLOCATE CALLBACKS /////////
    SecuredField.prototype.onIframeLoaded = function (callbackFn) {
        this.onIframeLoadedCallback = callbackFn;
        return this;
    };
    SecuredField.prototype.onEncryption = function (callbackFn) {
        this.onEncryptionCallback = callbackFn;
        return this;
    };
    SecuredField.prototype.onValidation = function (callbackFn) {
        this.onValidationCallback = callbackFn;
        return this;
    };
    SecuredField.prototype.onConfig = function (callbackFn) {
        this.onConfigCallback = callbackFn;
        return this;
    };
    SecuredField.prototype.onFocus = function (callbackFn) {
        this.onFocusCallback = callbackFn;
        return this;
    };
    SecuredField.prototype.onBinValue = function (callbackFn) {
        this.onBinValueCallback = callbackFn;
        return this;
    };
    SecuredField.prototype.onClick = function (callbackFn) {
        this.onClickCallback = callbackFn;
        return this;
    };
    SecuredField.prototype.onShiftTab = function (callbackFn) {
        this.onShiftTabCallback = callbackFn;
        return this;
    };
    SecuredField.prototype.onAutoComplete = function (callbackFn) {
        this.onAutoCompleteCallback = callbackFn;
        return this;
    };
    Object.defineProperty(SecuredField.prototype, "errorType", {
        //------------------------------------
        // ///////////// GETTERS/SETTERS //////////////
        get: function () {
            return this._errorType;
        },
        set: function (value) {
            this._errorType = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SecuredField.prototype, "hasError", {
        get: function () {
            return this._hasError;
        },
        set: function (value) {
            this._hasError = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SecuredField.prototype, "isValid", {
        get: function () {
            if (this.fieldType === ENCRYPTED_SECURITY_CODE) {
                if (!this.cvcRequired) {
                    // If cvc is optional then the field is always valid UNLESS it has an error
                    return !this.hasError;
                }
                return this._isValid && !this.hasError;
            }
            return this._isValid;
        },
        set: function (value) {
            this._isValid = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SecuredField.prototype, "cvcRequired", {
        get: function () {
            return this._cvcRequired;
        },
        set: function (value) {
            // Only set if this is a CVC field
            if (this.fieldType !== ENCRYPTED_SECURITY_CODE)
                return;
            // Only set if value has changed
            if (value === this.cvcRequired)
                return;
            if (process.env.NODE_ENV === 'development' && doLog)
                logger.log(this.fieldType, '### SecuredFieldCls::cvcRequired:: value=', value);
            this._cvcRequired = value;
            // If the field has changed status (required <--> not required) AND it's error state was due to an isValidated call
            // NOTE: fixes issue in Components where you first validate and then start typing a maestro number
            // - w/o this and the fix in CSF the maestro PM will never register as valid
            if (this.hasError && this.errorType === 'isValidated') {
                this.hasError = false;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SecuredField.prototype, "iframeContentWindow", {
        get: function () {
            return this._iframeContentWindow;
        },
        set: function (value) {
            this._iframeContentWindow = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SecuredField.prototype, "isEncrypted", {
        get: function () {
            return this._isEncrypted;
        },
        set: function (value) {
            this._isEncrypted = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SecuredField.prototype, "numKey", {
        get: function () {
            return this._numKey;
        },
        set: function (value) {
            this._numKey = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SecuredField.prototype, "iframeOnLoadListener", {
        // Internal use - way to create listener refs that we can add/remove
        get: function () {
            return this._iframeOnLoadListener;
        },
        set: function (value) {
            this._iframeOnLoadListener = value.bind(this);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SecuredField.prototype, "postMessageListener", {
        get: function () {
            return this._postMessageListener;
        },
        set: function (value) {
            this._postMessageListener = value.bind(this);
        },
        enumerable: false,
        configurable: true
    });
    return SecuredField;
}(AbstractSecuredField));
export default SecuredField;
