import { __assign, __extends, __spreadArrays } from "tslib";
import { Component, h } from 'preact';
import PaymentMethodList from './PaymentMethod/PaymentMethodList';
import { createElements, createStoredElements } from '../elements';
import { getCommonProps } from './utils';
import Status from './status';
import getProp from '~/utils/getProp';
import './DropinComponent.scss';
var DropinComponent = /** @class */ (function (_super) {
    __extends(DropinComponent, _super);
    function DropinComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            elements: [],
            isDisabling: false,
            status: { type: 'loading' },
            activePaymentMethod: null,
            cachedPaymentMethods: {}
        };
        _this.setStatus = function (status) {
            _this.setState({ status: status });
        };
        _this.setActivePaymentMethod = function (paymentMethod) {
            _this.setState(function (prevState) {
                var _a;
                return ({
                    activePaymentMethod: paymentMethod,
                    cachedPaymentMethods: __assign(__assign({}, prevState.cachedPaymentMethods), (_a = {}, _a[paymentMethod.props.id] = true, _a))
                });
            });
        };
        _this.handleOnSelectPaymentMethod = function (paymentMethod) {
            var activePaymentMethod = _this.state.activePaymentMethod;
            _this.setActivePaymentMethod(paymentMethod);
            // onSelect event
            if ((activePaymentMethod && activePaymentMethod.props.id !== paymentMethod.props.id) || !activePaymentMethod) {
                _this.props.onSelect(paymentMethod);
            }
        };
        _this.handleDisableStoredPaymentMethod = function (storedPaymentMethod) {
            _this.setState({ isDisabling: true });
            new Promise(function (resolve, reject) { return _this.props.onDisableStoredPaymentMethod(storedPaymentMethod, resolve, reject); })
                .then(function () {
                _this.setState(function (prevState) { return ({ elements: prevState.elements.filter(function (pm) { return pm.props.id !== storedPaymentMethod.props.id; }) }); });
                _this.setState({ isDisabling: false });
            })
                .catch(function () {
                _this.setState({ isDisabling: false });
            });
        };
        return _this;
    }
    DropinComponent.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, paymentMethodsConfiguration = _a.paymentMethodsConfiguration, paymentMethods = _a.paymentMethods, storedPaymentMethods = _a.storedPaymentMethods;
        var commonProps = getCommonProps(this.props);
        var storedElementsPromises = this.props.showStoredPaymentMethods
            ? createStoredElements(storedPaymentMethods, commonProps, paymentMethodsConfiguration)
            : [];
        var elementsPromises = this.props.showPaymentMethods ? createElements(paymentMethods, commonProps, paymentMethodsConfiguration) : [];
        Promise.all([storedElementsPromises, elementsPromises]).then(function (_a) {
            var storedElements = _a[0], elements = _a[1];
            _this.setState({ elements: __spreadArrays(storedElements, elements) });
            _this.setStatus({ type: 'ready' });
            if (_this.props.modules.analytics) {
                _this.props.modules.analytics.send({
                    containerWidth: _this.base && _this.base.offsetWidth,
                    paymentMethods: elements.map(function (e) { return e.props.type; }),
                    component: 'dropin',
                    flavor: 'dropin'
                });
            }
        });
    };
    DropinComponent.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (prevState.status.type !== this.state.status.type && this.state.activePaymentMethod) {
            this.state.activePaymentMethod.setStatus(this.state.status.type);
        }
        if (this.state.status.type === 'ready' && prevState.status.type !== 'ready' && this.props.onReady) {
            this.props.onReady();
        }
    };
    DropinComponent.prototype.render = function (props, _a) {
        var elements = _a.elements, status = _a.status, activePaymentMethod = _a.activePaymentMethod, cachedPaymentMethods = _a.cachedPaymentMethods;
        var isLoading = status.type === 'loading';
        var isRedirecting = status.type === 'redirect';
        switch (status.type) {
            case 'success':
                return h(Status.Success, { message: getProp(status, 'props.message') || null });
            case 'error':
                return h(Status.Error, { message: getProp(status, 'props.message') || null });
            case 'custom':
                return status.props.component.render();
            default:
                return (h("div", { className: "adyen-checkout__dropin adyen-checkout__dropin--" + status.type },
                    isRedirecting && status.props.component && status.props.component.render(),
                    isLoading && status.props && status.props.component && status.props.component.render(),
                    elements && !!elements.length && (h(PaymentMethodList, { isLoading: isLoading || isRedirecting, isDisabling: this.state.isDisabling, paymentMethods: elements, activePaymentMethod: activePaymentMethod, cachedPaymentMethods: cachedPaymentMethods, onSelect: this.handleOnSelectPaymentMethod, openFirstPaymentMethod: this.props.openFirstPaymentMethod, openFirstStoredPaymentMethod: this.props.openFirstStoredPaymentMethod, onDisableStoredPaymentMethod: this.handleDisableStoredPaymentMethod, showRemovePaymentMethodButton: this.props.showRemovePaymentMethodButton }))));
        }
    };
    return DropinComponent;
}(Component));
export { DropinComponent };
export default DropinComponent;
