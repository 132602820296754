import { h } from 'preact';
import { useState } from 'preact/hooks';
import { renderFormField } from '~/components/internal/FormFields';
import useCoreContext from '~/core/Context/useCoreContext';
import Field from '~/components/internal/FormFields/Field';
export default function SendCopyToEmail(props) {
    var errors = props.errors, value = props.value, onInput = props.onInput, onChange = props.onChange;
    var i18n = useCoreContext().i18n;
    var _a = useState(false), sendCopyToEmail = _a[0], setSendCopyToEmail = _a[1];
    var toggleEmailField = function (e) {
        setSendCopyToEmail(e.target.checked);
        props.onToggle(sendCopyToEmail);
    };
    return (h("div", { className: 'adyen-checkout__fieldset adyen-checkout__fieldset--sendCopyToEmail' },
        h(Field, { classNameModifiers: ['sendCopyToEmail'] }, renderFormField('boolean', {
            onChange: toggleEmailField,
            label: i18n.get('boleto.sendCopyToEmail'),
            name: 'sendCopyToEmail',
            value: sendCopyToEmail
        })),
        sendCopyToEmail && (h(Field, { label: i18n.get('shopperEmail'), classNameModifiers: ['shopperEmail'], errorMessage: errors }, renderFormField('emailAddress', {
            name: 'boleto.shopperEmail',
            autoCorrect: 'off',
            spellCheck: false,
            value: value,
            onInput: onInput,
            onChange: onChange
        })))));
}
