import { __assign } from "tslib";
import { getCardImageUrl } from './utils';
import { ENCRYPTED_SECURITY_CODE } from './lib/configuration/constants';
/**
 * Emits the onConfigSuccess (ready) event
 * Here we can assume CSF is loaded and ready to be used
 */
function handleOnLoad(cbObj) {
    var _this = this;
    // Propagate onLoad event
    this.props.onLoad(cbObj);
    // eslint-disable-next-line
    this.originKeyErrorTimeout = setTimeout(function () {
        if (_this.state.status !== 'ready') {
            // Hide the spinner and lets look at the inputs
            _this.setState({ status: 'originKeyError' });
            _this.props.onError({ error: 'originKeyError', fieldType: 'defaultError' });
        }
    }, this.originKeyTimeoutMS);
}
/**
 * Emits the onConfigSuccess (ready) event
 * Here we can assume CSF is loaded, configured and ready to be used
 */
function handleOnConfigSuccess(cbObj) {
    var _this = this;
    clearTimeout(this.originKeyErrorTimeout);
    this.setState({ status: 'ready' }, function () {
        // Propagate onConfigSuccess event
        _this.props.onConfigSuccess(cbObj);
    });
}
/**
 * Emits the onAllValid event
 */
function handleOnAllValid(status) {
    var _this = this;
    this.setState({ isSfpValid: status.allValid }, function () {
        _this.props.onAllValid(status); // Propagate onAllValid event
    });
}
/**
 * Saves a field value from CSF in the CardInput state
 * Emits the onFieldValid event
 */
function handleOnFieldValid(field) {
    var _this = this;
    var setValidFieldState = function (prevState) {
        var _a, _b, _c;
        return ({
            data: __assign(__assign({}, prevState.data), (_a = {}, _a[field.encryptedFieldName] = field.blob, _a)),
            valid: __assign(__assign({}, prevState.valid), (_b = {}, _b[field.encryptedFieldName] = field.valid, _b)),
            // if a securedField comes through as valid:false it means the field has not been completed BUT is without errors - so set it as so (unless it was already in an error state)
            errors: __assign(__assign({}, prevState.errors), (_c = {}, _c[field.fieldType] = prevState.errors[field.fieldType] === true, _c))
        });
    };
    this.setState(setValidFieldState, function () {
        _this.props.onChange(_this.state);
        // Propagate onFieldValid event
        _this.props.onFieldValid(field);
    });
}
/**
 * Saves the card brand in state
 * Emits the onBrand event
 */
function handleOnBrand(cardInfo) {
    var _this = this;
    this.setState(function (prevState) {
        var _a;
        return ({
            brand: cardInfo.brand,
            cvcRequired: cardInfo.cvcRequired !== false,
            errors: __assign(__assign({}, prevState.errors), (_a = {}, _a[ENCRYPTED_SECURITY_CODE] = !cardInfo.cvcRequired && _this.numCharsInCVC === 0 ? false : prevState.errors[ENCRYPTED_SECURITY_CODE], _a))
        });
    }, function () {
        _this.props.onChange(_this.state);
        // Enhance data object with the url for the brand image
        _this.props.onBrand(__assign(__assign({}, cardInfo), { brandImageUrl: getCardImageUrl(cardInfo.brand, _this.props.loadingContext) }));
    });
    if ((this.props.hideCVC || !!cardInfo.hideCVC || cardInfo.cvcRequired === false) && this.props.oneClick) {
        this.handleOnNoDataRequired();
    }
}
/**
 * Handles validation errors
 */
function handleOnError(cbObj) {
    this.setState(function (prevState) {
        var _a;
        return ({
            errors: __assign(__assign({}, prevState.errors), (_a = {}, _a[cbObj.fieldType] = cbObj.error || false, _a))
        });
    });
    this.props.onError(cbObj);
}
function handleFocus(cbObj) {
    if (cbObj.fieldType === ENCRYPTED_SECURITY_CODE) {
        this.numCharsInCVC = cbObj.numChars;
    }
    this.props.onFocus(cbObj);
}
// Only called for holder name
function handleOnAutoComplete(cbObj) {
    var _this = this;
    this.setState({ autoCompleteName: cbObj.value }, function () {
        _this.props.onChange(_this.state);
        _this.setState({ autoCompleteName: null }); // Nullify ref after sending it (lets shopper edit holder name)
    });
    this.props.onAutoComplete(cbObj);
}
/**
 * Handles cases where no secured fields are necessary (one click payments without CVC)
 * Automatically resolves with a valid state
 */
function handleOnNoDataRequired() {
    var _this = this;
    this.setState({ status: 'ready' }, function () { return _this.props.onChange({ isSfpValid: true }); });
}
export default {
    handleFocus: handleFocus,
    handleOnAllValid: handleOnAllValid,
    handleOnAutoComplete: handleOnAutoComplete,
    handleOnFieldValid: handleOnFieldValid,
    handleOnLoad: handleOnLoad,
    handleOnConfigSuccess: handleOnConfigSuccess,
    handleOnBrand: handleOnBrand,
    handleOnError: handleOnError,
    handleOnNoDataRequired: handleOnNoDataRequired
};
