import { __assign, __extends } from "tslib";
import { h } from 'preact';
import BaseElement from './BaseElement';
import getImage from '../utils/get-image';
import PayButton from './internal/PayButton';
var UIElement = /** @class */ (function (_super) {
    __extends(UIElement, _super);
    function UIElement(props) {
        var _this = _super.call(this, props) || this;
        _this.payButton = function (props) {
            return h(PayButton, __assign({}, props, { amount: _this.props.amount, onClick: _this.submit }));
        };
        _this.submit = _this.submit.bind(_this);
        _this.setState = _this.setState.bind(_this);
        _this.onValid = _this.onValid.bind(_this);
        _this.onComplete = _this.onComplete.bind(_this);
        _this.handleAction = _this.handleAction.bind(_this);
        _this.elementRef = (props && props.elementRef) || _this;
        return _this;
    }
    UIElement.prototype.setState = function (newState) {
        this.state = __assign(__assign({}, this.state), newState);
        this.onChange();
    };
    UIElement.prototype.onChange = function () {
        var isValid = this.isValid;
        var state = { data: this.data, isValid: isValid };
        if (this.props.onChange)
            this.props.onChange(state, this);
        if (isValid)
            this.onValid();
        return state;
    };
    UIElement.prototype.onValid = function () {
        var state = { data: this.data };
        if (this.props.onValid)
            this.props.onValid(state, this);
        return state;
    };
    UIElement.prototype.startPayment = function () {
        return Promise.resolve(true);
    };
    UIElement.prototype.submit = function () {
        var _this = this;
        var _a = this.props, _b = _a.onError, onError = _b === void 0 ? function () { } : _b, _c = _a.onSubmit, onSubmit = _c === void 0 ? function () { } : _c;
        this.startPayment()
            .then(function () {
            var _a = _this, data = _a.data, isValid = _a.isValid;
            if (!isValid) {
                _this.showValidation();
                return false;
            }
            return onSubmit({ data: data, isValid: isValid }, _this);
        })
            .catch(function (error) { return onError(error); });
    };
    UIElement.prototype.onComplete = function (state) {
        if (this.props.onComplete)
            this.props.onComplete(state, this);
    };
    UIElement.prototype.showValidation = function () {
        if (this.componentRef && this.componentRef.showValidation)
            this.componentRef.showValidation();
        return this;
    };
    UIElement.prototype.setStatus = function (status) {
        if (this.componentRef && this.componentRef.setStatus)
            this.componentRef.setStatus(status);
        return this;
    };
    UIElement.prototype.handleAction = function (action) {
        var _this = this;
        if (!action || !action.type)
            throw new Error('Invalid Action');
        var paymentAction = this.props.createFromAction(action, {
            onAdditionalDetails: function (state) { return _this.props.onAdditionalDetails(state, _this.elementRef); }
        });
        if (paymentAction) {
            this.unmount();
            paymentAction.mount(this._node);
            return paymentAction;
        }
        return null;
    };
    Object.defineProperty(UIElement.prototype, "isValid", {
        get: function () {
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UIElement.prototype, "icon", {
        get: function () {
            return getImage({ loadingContext: this.props.loadingContext })(this.constructor['type']);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UIElement.prototype, "displayName", {
        get: function () {
            return this.props.name || this.constructor['type'];
        },
        enumerable: false,
        configurable: true
    });
    return UIElement;
}(BaseElement));
export { UIElement };
export default UIElement;
