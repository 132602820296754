import { __extends } from "tslib";
import { Component, h } from 'preact';
import styles from './ApplePayButton.module.scss';
import './ApplePayButton.scss';
var ApplePayButton = /** @class */ (function (_super) {
    __extends(ApplePayButton, _super);
    function ApplePayButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ApplePayButton.prototype.render = function (_a) {
        var buttonColor = _a.buttonColor, buttonType = _a.buttonType;
        return (h("div", { className: "adyen-checkout__applepay__button\n                            adyen-checkout__applepay__button--" + buttonColor + "\n                            adyen-checkout__applepay__button--" + buttonType + "\n                            " + styles['apple-pay-button'] + "\n                            " + styles["apple-pay-button-" + buttonColor] + "\n                            " + styles["apple-pay-button--type-" + buttonType], onClick: this.props.onClick }));
    };
    ApplePayButton.defaultProps = {
        onClick: function () { },
        buttonColor: 'black',
        buttonType: 'plain'
    };
    return ApplePayButton;
}(Component));
export default ApplePayButton;
