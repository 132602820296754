import { __assign } from "tslib";
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import CampaignContainer from './CampaignContainer';
import ButtonGroup from '../../internal/ButtonGroup';
import Button from '../../internal/Button';
import Img from '../../internal/Img';
import { getImageUrl } from '~/utils/get-image';
import useCoreContext from '~/core/Context/useCoreContext';
import '../Donation.scss';
export default function DonationComponent(props) {
    var amounts = props.amounts, onCancel = props.onCancel, onDonate = props.onDonate, _a = props.showCancelButton, showCancelButton = _a === void 0 ? true : _a;
    var _b = useCoreContext(), i18n = _b.i18n, loadingContext = _b.loadingContext;
    var currency = amounts.currency;
    var _c = useState('ready'), status = _c[0], setStatus = _c[1];
    var _d = useState(false), isValid = _d[0], setIsValid = _d[1];
    var _e = useState({
        currency: currency,
        value: null
    }), amount = _e[0], setAmount = _e[1];
    this.setStatus = function (status) {
        setStatus(status);
    };
    var getAmount = function (value, currency) { return i18n.amount(value, currency, { minimumFractionDigits: 0, maximumFractionDigits: 0 }); };
    var handleAmountSelected = function (_a) {
        var target = _a.target;
        var value = parseInt(target.value, 10);
        setIsValid(true);
        setAmount(function (amount) { return (__assign(__assign({}, amount), { value: value })); });
    };
    var handleDonate = function () {
        setStatus('loading');
        onDonate({ data: { amount: amount } });
    };
    var handleDecline = function () {
        setStatus('loading');
        onCancel({ data: { amount: amount }, isValid: isValid });
    };
    useEffect(function () {
        props.onChange({ data: { amount: amount }, isValid: isValid });
    }, [amount, isValid]);
    if (status === 'error') {
        return (h("div", { className: "adyen-checkout__adyen-giving" },
            h(Img, { className: "adyen-checkout__status__icon adyen-checkout__status__icon--error", src: getImageUrl({ loadingContext: loadingContext, imageFolder: 'components/' })('error'), alt: i18n.get('error.message.unknown') }),
            h("div", { className: "adyen-checkout__status__text" }, i18n.get('error.message.unknown'))));
    }
    if (status === 'success') {
        return (h("div", { className: "adyen-checkout__adyen-giving" },
            h(Img, { className: "adyen-checkout__status__icon adyen-checkout__status__icon--success", src: getImageUrl({ loadingContext: loadingContext, imageFolder: 'components/' })('heart'), alt: i18n.get('thanksForYourSupport') }),
            h("div", { className: "adyen-checkout__status__text" }, i18n.get('thanksForYourSupport'))));
    }
    return (h("div", { className: "adyen-checkout__adyen-giving" },
        h(CampaignContainer, __assign({}, props)),
        h("div", { className: "adyen-checkout__adyen-giving-actions" },
            h("div", { className: "adyen-checkout__amounts" },
                h(ButtonGroup, { options: amounts.values.map(function (value) { return ({
                        value: value,
                        label: getAmount(value, currency),
                        disabled: status === 'loading',
                        selected: value === amount.value
                    }); }), name: "amount", onChange: handleAmountSelected })),
            h(Button, { classNameModifiers: ['donate'], onClick: handleDonate, label: i18n.get('donateButton'), disabled: !amount.value, status: status }),
            showCancelButton && (h(Button, { classNameModifiers: ['ghost', 'decline'], onClick: handleDecline, disabled: status === 'loading', label: i18n.get('notNowButton') + " \u203A" })))));
}
DonationComponent.defaultProps = {
    onCancel: function () { },
    onChange: function () { },
    onDonate: function () { },
    amounts: {},
    showCancelButton: true
};
