import { h } from 'preact';
import classNames from 'classnames';
import styles from '../AchInput.module.scss';
import Field from '~/components/internal/FormFields/Field';
var AchSFInput = function (_a) {
    var _b;
    var id = _a.id, dataInfo = _a.dataInfo, _c = _a.className, className = _c === void 0 ? '' : _c, label = _a.label, focused = _a.focused, filled = _a.filled, _d = _a.errorMessage, errorMessage = _d === void 0 ? '' : _d, _e = _a.isValid, isValid = _e === void 0 ? false : _e, _f = _a.onFocusField, onFocusField = _f === void 0 ? function () { } : _f;
    var capitalisedId = id.charAt(0).toUpperCase() + id.slice(1);
    var encryptedIdStr = "encrypted" + capitalisedId;
    return (h(Field, { label: label, focused: focused, filled: filled, classNameModifiers: [id], onFocusField: function () { return onFocusField(encryptedIdStr); }, errorMessage: errorMessage, isValid: isValid, className: className },
        h("span", { "data-cse": encryptedIdStr, "data-info": dataInfo, className: classNames((_b = {
                    'adyen-checkout__input': true,
                    'adyen-checkout__input--large': true
                },
                _b[styles['adyen-checkout__input']] = true,
                _b['adyen-checkout__input--error'] = errorMessage.length,
                _b['adyen-checkout__input--focus'] = focused,
                _b['adyen-checkout__input--valid'] = isValid,
                _b)) })));
};
export default AchSFInput;
