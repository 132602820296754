import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import CoreProvider from '../../core/Context/CoreProvider';
import DonationComponent from './components/DonationComponent';
/**
 * DonationElement
 * @extends UIElement
 */
var DonationElement = /** @class */ (function (_super) {
    __extends(DonationElement, _super);
    function DonationElement(props) {
        var _this = _super.call(this, props) || this;
        _this.handleRef = function (ref) {
            _this.componentRef = ref;
        };
        _this.donate = _this.donate.bind(_this);
        return _this;
    }
    Object.defineProperty(DonationElement.prototype, "data", {
        /**
         * Returns the component payment data ready to submit to the Checkout API
         * @return {object} data
         */
        get: function () {
            return this.state.data;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DonationElement.prototype, "isValid", {
        /**
         * Returns whether the component state is valid or not
         * @return {boolean} isValid
         */
        get: function () {
            return this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    DonationElement.prototype.setState = function (newState) {
        this.state = __assign(__assign({}, this.state), newState);
    };
    DonationElement.prototype.donate = function () {
        var _a = this, data = _a.data, isValid = _a.isValid;
        this.props.onDonate({ data: data, isValid: isValid }, this);
    };
    DonationElement.prototype.render = function () {
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
            h(DonationComponent, __assign({}, this.props, { ref: this.handleRef, onChange: this.setState, onDonate: this.donate }))));
    };
    DonationElement.type = 'donation';
    DonationElement.defaultProps = {
        onCancel: function () { },
        onDonate: function () { }
    };
    return DonationElement;
}(UIElement));
export default DonationElement;
