import { __assign } from "tslib";
import { h } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import Fieldset from '../FormFields/Fieldset';
import Field from '../FormFields/Field';
import ReadOnlyPersonalDetails from './ReadOnlyPersonalDetails';
import { renderFormField } from '../FormFields';
import { personalDetailsValidationRules } from './validate';
import Validator from '~/utils/Validator';
import useCoreContext from '~/core/Context/useCoreContext';
import { checkDateInputSupport } from '~/components/internal/FormFields/InputDate/utils';
var personalDetailsSchema = ['firstName', 'lastName', 'gender', 'dateOfBirth', 'telephoneNumber', 'shopperEmail'];
export default function PersonalDetails(props) {
    var _a = props.label, label = _a === void 0 ? '' : _a, namePrefix = props.namePrefix, requiredFields = props.requiredFields, visibility = props.visibility;
    var i18n = useCoreContext().i18n;
    var validator = new Validator(personalDetailsValidationRules);
    var _b = useState(props.data), data = _b[0], setData = _b[1];
    var _c = useState({}), errors = _c[0], setErrors = _c[1];
    var _d = useState({}), valid = _d[0], setValid = _d[1];
    var isDateInputSupported = useMemo(checkDateInputSupport, []);
    var eventHandler = function (mode) { return function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var key = name.split(namePrefix + ".").pop();
        var isValid = validator.validate(key, mode)(value);
        setData(function (data) {
            var _a;
            return (__assign(__assign({}, data), (_a = {}, _a[key] = value, _a)));
        });
        setValid(function (valid) {
            var _a;
            return (__assign(__assign({}, valid), (_a = {}, _a[key] = isValid, _a)));
        });
        setErrors(function (errors) {
            var _a;
            return (__assign(__assign({}, errors), (_a = {}, _a[key] = !isValid, _a)));
        });
    }; };
    var generateFieldName = function (name) { return "" + (namePrefix ? namePrefix + "." : '') + name; };
    useEffect(function () {
        var isValid = requiredFields.every(function (field) { return validator.validate(field, 'blur')(data[field]); });
        props.onChange({ data: data, isValid: isValid });
    }, [data, valid, errors]);
    this.showValidation = function () {
        var errorsReducer = function (acc, field) {
            acc[field] = !validator.validate(field, 'blur')(data[field]);
            return acc;
        };
        setErrors(requiredFields.reduce(errorsReducer, {}));
    };
    if (visibility === 'hidden')
        return null;
    if (visibility === 'readOnly')
        return h(ReadOnlyPersonalDetails, __assign({}, props, { data: data }));
    return (h(Fieldset, { classNameModifiers: [label], label: label },
        requiredFields.includes('firstName') && (h(Field, { label: i18n.get('firstName'), classNameModifiers: ['col-50', 'firstName'], errorMessage: !!errors.firstName }, renderFormField('text', {
            name: generateFieldName('firstName'),
            value: data.firstName,
            classNameModifiers: ['firstName'],
            onInput: eventHandler('input'),
            onChange: eventHandler('blur'),
            spellCheck: false
        }))),
        requiredFields.includes('lastName') && (h(Field, { label: i18n.get('lastName'), classNameModifiers: ['col-50', 'lastName'], errorMessage: !!errors.lastName }, renderFormField('text', {
            name: generateFieldName('lastName'),
            value: data.lastName,
            classNameModifiers: ['lastName'],
            onInput: eventHandler('input'),
            onChange: eventHandler('blur'),
            spellCheck: false
        }))),
        requiredFields.includes('gender') && (h(Field, { errorMessage: !!errors.gender, classNameModifiers: ['gender'] }, renderFormField('radio', {
            i18n: i18n,
            name: generateFieldName('gender'),
            value: data.gender,
            items: [
                { id: 'MALE', name: 'male' },
                { id: 'FEMALE', name: 'female' }
            ],
            classNameModifiers: ['gender'],
            onInput: eventHandler('input'),
            onChange: eventHandler('blur')
        }))),
        requiredFields.includes('dateOfBirth') && (h(Field, { label: i18n.get('dateOfBirth'), classNameModifiers: ['col-50', 'lastName'], errorMessage: !!errors.dateOfBirth, helper: isDateInputSupported ? null : i18n.get('dateOfBirth.format') }, renderFormField('date', {
            name: generateFieldName('dateOfBirth'),
            value: data.dateOfBirth,
            classNameModifiers: ['dateOfBirth'],
            onInput: eventHandler('input'),
            onChange: eventHandler('blur')
        }))),
        requiredFields.includes('telephoneNumber') && (h(Field, { label: i18n.get('telephoneNumber'), classNameModifiers: ['telephoneNumber'], errorMessage: !!errors.telephoneNumber }, renderFormField('tel', {
            name: generateFieldName('telephoneNumber'),
            value: data.telephoneNumber,
            classNameModifiers: ['telephoneNumber'],
            onInput: eventHandler('input'),
            onChange: eventHandler('blur')
        }))),
        requiredFields.includes('shopperEmail') && (h(Field, { label: i18n.get('shopperEmail'), classNameModifiers: ['shopperEmail'], errorMessage: !!errors.shopperEmail }, renderFormField('emailAddress', {
            name: generateFieldName('shopperEmail'),
            value: data.shopperEmail,
            classNameModifiers: ['shopperEmail'],
            onInput: eventHandler('input'),
            onChange: eventHandler('blur')
        })))));
}
PersonalDetails.defaultProps = {
    data: {},
    onChange: function () { },
    visibility: 'editable',
    requiredFields: personalDetailsSchema
};
