import { h } from 'preact';
import Fieldset from '../../FormFields/Fieldset';
var ReadOnlyAddress = function (_a) {
    var data = _a.data, label = _a.label;
    var street = data.street, houseNumberOrName = data.houseNumberOrName, city = data.city, postalCode = data.postalCode, stateOrProvince = data.stateOrProvince, country = data.country;
    return (h(Fieldset, { classNameModifiers: [label], label: label, readonly: true },
        street && street,
        houseNumberOrName && ", " + houseNumberOrName + ",",
        h("br", null),
        postalCode && "" + postalCode,
        city && ", " + city,
        stateOrProvince && stateOrProvince !== 'N/A' && ", " + stateOrProvince,
        country && ", " + country + " "));
};
export default ReadOnlyAddress;
