var shortestPermittedCardLength;
var CardType = {};
CardType.__NO_BRAND = 'noBrand';
CardType.cards = [];
CardType.cards.push({
    cardType: 'mc',
    displayName: 'Mastercard',
    startingRules: [51, 52, 53, 54, 55, 22, 23, 24, 25, 26, 27],
    permittedLengths: [16],
    pattern: /^(5[1-5][0-9]{0,14}|2[2-7][0-9]{0,14})$/,
    securityCode: 'CVC'
});
CardType.cards.push({ cardType: 'visadankort', startingRules: [4571], permittedLengths: [16], pattern: /^(4571)[0-9]{0,12}$/ });
CardType.cards.push({
    cardType: 'visa',
    displayName: 'Visa',
    startingRules: [4],
    permittedLengths: [13, 16, 19],
    pattern: /^4[0-9]{0,18}$/,
    securityCode: 'CVV'
});
CardType.cards.push({ cardType: 'amex', startingRules: [34, 37], permittedLengths: [15], pattern: /^3[47][0-9]{0,13}$/, securityCode: 'CID' });
CardType.cards.push({ cardType: 'diners', startingRules: [36], permittedLengths: [14], pattern: /^(36)[0-9]{0,12}$/ });
CardType.cards.push({ cardType: 'maestrouk', startingRules: [6759], permittedLengths: [16, 18, 19], pattern: /^(6759)[0-9]{0,15}$/ });
CardType.cards.push({ cardType: 'solo', startingRules: [6767], permittedLengths: [16, 18, 19], pattern: /^(6767)[0-9]{0,15}$/ });
CardType.cards.push({
    cardType: 'laser',
    startingRules: [6304, 6706, 677117, 677120],
    permittedLengths: [16, 17, 18, 19],
    pattern: /^(6304|6706|6709|6771)[0-9]{0,15}$/,
    cvcRequired: false
});
CardType.cards.push({
    cardType: 'discover',
    startingRules: [6011, 644, 645, 646, 647, 648, 649, 65],
    permittedLengths: [16],
    pattern: /^(6011[0-9]{0,12}|(644|645|646|647|648|649)[0-9]{0,13}|65[0-9]{0,14})$/
});
CardType.cards.push({
    cardType: 'jcb',
    startingRules: [3528, 3529, 353, 354, 355, 356, 357, 358],
    permittedLengths: [16, 19],
    pattern: /^(352[8,9]{1}[0-9]{0,15}|35[4-8]{1}[0-9]{0,16})$/,
    securityCode: 'CAV'
});
CardType.cards.push({
    cardType: 'bcmc',
    startingRules: [6703, 479658, 606005],
    permittedLengths: [16, 17, 18, 19],
    pattern: /^((6703)[0-9]{0,15}|(479658|606005)[0-9]{0,13})$/,
    cvcRequired: false,
    hideCVC: true
});
CardType.cards.push({ cardType: 'bijcard', startingRules: [5100081], permittedLengths: [16], pattern: /^(5100081)[0-9]{0,9}$/ });
CardType.cards.push({ cardType: 'dankort', startingRules: [5019], permittedLengths: [16], pattern: /^(5019)[0-9]{0,12}$/ });
CardType.cards.push({ cardType: 'hipercard', startingRules: [606282], permittedLengths: [16], pattern: /^(606282)[0-9]{0,10}$/ });
// Moved above maestro (from position below uatp) to stop maestro being recognised over cup
CardType.cards.push({ cardType: 'cup', startingRules: [62, 81], permittedLengths: [14, 15, 16, 17, 18, 19], pattern: /^(62|81)[0-9]{0,17}$/ }); // orig & android v1 + modified to include our test cards (81...)
CardType.cards.push({
    cardType: 'maestro',
    startingRules: [50, 56, 57, 58, 6],
    permittedLengths: [16, 17, 18, 19],
    pattern: /^(5[0|6-8][0-9]{0,17}|6[0-9]{0,18})$/,
    cvcRequired: false
});
CardType.cards.push({
    cardType: 'elo',
    startingRules: [
        506699,
        50670,
        50671,
        50672,
        50673,
        50674,
        50675,
        50676,
        506770,
        506771,
        506772,
        506773,
        506774,
        506775,
        506776,
        506777,
        506778,
        401178,
        438935,
        451416,
        457631,
        457632,
        504175,
        627780,
        636297,
        636368,
        651653,
        506728,
        509096,
        509083,
        509082,
        655001,
        650487,
        509081,
        509074,
        509066,
        431274,
        438935,
        457631,
        457632,
        506744,
        506747,
        506748,
        506753,
        509069,
        650906,
        506730,
        509067,
        655003,
        509068
    ],
    permittedLengths: [16],
    pattern: /^((((506699)|(506770)|(506771)|(506772)|(506773)|(506774)|(506775)|(506776)|(506777)|(506778)|(401178)|(438935)|(451416)|(457631)|(457632)|(504175)|(627780)|(636368)|(636297))[0-9]{0,10})|((50676)|(50675)|(50674)|(50673)|(50672)|(50671)|(50670))[0-9]{0,11})$/ // eslint-disable-line max-len
});
CardType.cards.push({ cardType: 'uatp', startingRules: [1], permittedLengths: [15], pattern: /^1[0-9]{0,14}$/, cvcRequired: false });
CardType.cards.push({
    cardType: 'cartebancaire',
    displayName: 'Cartes Bancaires',
    startingRules: [4, 5, 6],
    permittedLengths: [16],
    pattern: /^[4-6][0-9]{0,15}$/
});
CardType.cards.push({ cardType: 'visaalphabankbonus', startingRules: [450903], permittedLengths: [16], pattern: /^(450903)[0-9]{0,10}$/ });
CardType.cards.push({ cardType: 'mcalphabankbonus', startingRules: [510099], permittedLengths: [16], pattern: /^(510099)[0-9]{0,10}$/ });
CardType.cards.push({
    cardType: 'hiper',
    startingRules: [637095, 637568, 637599, 637609, 637612],
    permittedLengths: [16],
    pattern: /^(637095|637568|637599|637609|637612)[0-9]{0,10}$/
});
CardType.cards.push({ cardType: 'oasis', startingRules: [982616], permittedLengths: [16], pattern: /^(982616)[0-9]{0,10}$/, cvcRequired: false });
CardType.cards.push({
    cardType: 'karenmillen',
    startingRules: [98261465],
    permittedLengths: [16],
    pattern: /^(98261465)[0-9]{0,8}$/,
    cvcRequired: false
});
CardType.cards.push({ cardType: 'warehouse', startingRules: [982633], permittedLengths: [16], pattern: /^(982633)[0-9]{0,10}$/, cvcRequired: false });
CardType.cards.push({ cardType: 'mir', startingRules: [220], permittedLengths: [16, 17, 18, 19], pattern: /^(220)[0-9]{0,16}$/ });
CardType.cards.push({ cardType: 'codensa', startingRules: [590712], permittedLengths: [16], pattern: /^(590712)[0-9]{0,10}$/ });
CardType.cards.push({
    cardType: 'naranja',
    startingRules: [377798, 377799, 402917, 402918, 527571, 527572, 589562],
    permittedLengths: [16, 17, 18, 19],
    pattern: /^(37|40|5[28])([279])\d*$/
});
// TODO: 589657 clashes with naranja, rest ok
CardType.cards.push({
    cardType: 'cabal',
    startingRules: [589657, 600691, 603522, 6042, 6043, 636908],
    permittedLengths: [16, 17, 18, 19],
    pattern: /^(58|6[03])([03469])\d*$/
});
CardType.cards.push({
    cardType: 'shopping',
    startingRules: [2799, 589407, 603488],
    permittedLengths: [16, 17, 18, 19],
    pattern: /^(27|58|60)([39])\d*$/
});
CardType.cards.push({ cardType: 'argencard', startingRules: [501], permittedLengths: [16, 17, 18, 19], pattern: /^(50)(1)\d*$/ }); // NOTE: starting rule changed, from 501105, to not clash with dankort. Plus it now matches its regEx!
CardType.cards.push({ cardType: 'troy', startingRules: [9792], permittedLengths: [16], pattern: /^(97)(9)\d*$/ });
// TODO: clashes with cabal
CardType.cards.push({ cardType: 'forbrugsforeningen', startingRules: [600722], permittedLengths: [16], pattern: /^(60)(0)\d*$/ });
CardType.cards.push({
    cardType: 'vpay',
    startingRules: [401, 408, 413, 434, 435, 437, 439, 441, 442, 443, 444, 446, 447, 455, 458, 460, 461, 463, 466, 471, 479, 482, 483, 487],
    permittedLengths: [13, 14, 15, 16, 17, 18, 19],
    pattern: /^(40[1,8]|413|43[4,5]|44[1,2,3,4,6,7]|45[5,8]|46[0,1,3,6]|47[1,9]|48[2,3,7])[0-9]{0,16}$/ // ^(4[0-1|3-8][0-9]{1,17})$
});
var detectCard = function (pCardNumber, pAvailableCards) {
    var matchedCards;
    var i;
    var len;
    if (pAvailableCards) {
        // Filter CardType.cards down to those that are found in pAvailableCards
        matchedCards = CardType.cards
            .filter(function (card) { return pAvailableCards.includes(card.cardType); })
            // Further filter them to those with a regEx pattern that matches pCardNumber
            .filter(function (card) { return Object.prototype.hasOwnProperty.call(card, 'pattern') && pCardNumber.match(card.pattern); });
        // If we have matched cards: if there's only one - return it; else return the one with the longest startingRule
        if (matchedCards.length) {
            if (matchedCards.length === 1) {
                return matchedCards[0];
            }
            // Find longest rule for each matched card & store it as a property on the card
            for (i = 0, len = matchedCards.length; i < len; i += 1) {
                if (!matchedCards[i].longestRule) {
                    var longestRule = matchedCards[i].startingRules.reduce(function (a, b) { return (a > b ? a : b); });
                    // What we actually store is how many chars are in the rule
                    matchedCards[i].longestRule = String(longestRule).length;
                }
            }
            // Based on each matched cards longest rule - find the card with the longest one!
            var cardWithLongestRule = matchedCards.reduce(function (a, b) { return (a.longestRule >= b.longestRule ? a : b); });
            return cardWithLongestRule;
        }
        return { cardType: CardType.__NO_BRAND };
    }
    return { cardType: CardType.__NO_BRAND };
};
var detectCardLength = function (pCard, pUnformattedVal) {
    var maxLength;
    var shortenedNewValue;
    var lengthDiff = 0;
    var reachedValidLength = false;
    var unformattedVal = pUnformattedVal;
    // Find the longest of the permitted card number lengths for this card brand
    var maxPermittedLength = pCard.cardType !== CardType.__NO_BRAND ? pCard.permittedLengths[pCard.permittedLengths.length - 1] : 0;
    // If the input value is longer than it's max permitted length then shorten it to that length
    if (maxPermittedLength && unformattedVal > maxPermittedLength) {
        lengthDiff = unformattedVal.length - maxPermittedLength;
        if (lengthDiff > 0) {
            unformattedVal = unformattedVal.substring(0, unformattedVal.length - lengthDiff);
            shortenedNewValue = unformattedVal;
        }
    }
    // If cardNumber has reached one of the cardBrand's 'permitted lengths' - mark it as 'valid'
    pCard.permittedLengths.forEach(function (pItem) {
        if (unformattedVal.length === pItem) {
            reachedValidLength = true;
        }
    });
    // If cardNumber is as long as the cardBrand's maximum permitted length then set the maxLength var
    if (unformattedVal.length === maxPermittedLength) {
        // Set maxlength to max + the right amount of spaces (one for every 4 digits, but not on the last block)
        var div = Math.floor(unformattedVal.length / 4);
        var mod = unformattedVal.length % 4;
        var numSpaces = mod > 0 ? div : div - 1;
        maxLength = maxPermittedLength + numSpaces;
        if (pCard.cardType.toLowerCase() === 'amex') {
            maxLength = maxPermittedLength + 2; // = 17 = 15 digits with space after 4th & 10th
        }
    }
    return {
        shortenedNewValue: shortenedNewValue,
        maxLength: maxLength,
        reachedValidLength: reachedValidLength
    };
};
var getShortestPermittedCardLength = function () {
    if (!shortestPermittedCardLength) {
        var permittedLengthsArray_1 = [];
        CardType.cards.forEach(function (pItem) {
            permittedLengthsArray_1 = permittedLengthsArray_1.concat(pItem.permittedLengths);
        });
        shortestPermittedCardLength = Math.min.apply(null, permittedLengthsArray_1);
    }
    return shortestPermittedCardLength;
};
var getCardByBrand = function (pBrand) {
    var cardType = CardType.cards.filter(function (card) { return card.cardType === pBrand; });
    return cardType[0];
};
var getDisplayName = function (pBrand) {
    var card = getCardByBrand(pBrand);
    return card ? card.displayName : null;
};
var isGenericCardType = function (type) {
    if (type === void 0) { type = 'card'; }
    return type === 'card' || type === 'scheme';
};
export default {
    detectCard: detectCard,
    detectCardLength: detectCardLength,
    getShortestPermittedCardLength: getShortestPermittedCardLength,
    getCardByBrand: getCardByBrand,
    getDisplayName: getDisplayName,
    isGenericCardType: isGenericCardType,
    __NO_BRAND: CardType.__NO_BRAND,
    allCards: CardType.cards
};
