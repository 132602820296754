import { __assign, __extends, __spreadArrays } from "tslib";
import { Component, h } from 'preact';
import classNames from 'classnames';
import SecuredFieldsProvider from '~/components/internal/SecuredFields/SecuredFieldsProvider';
import useCoreContext from '~/core/Context/useCoreContext';
import Field from '~/components/internal/FormFields/Field';
var Giftcard = /** @class */ (function (_super) {
    __extends(Giftcard, _super);
    function Giftcard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            status: 'ready',
            data: {},
            focusedElement: false,
            isValid: false
        };
        _this.onChange = function (sfpState) {
            _this.props.onChange({
                data: sfpState.data,
                isValid: sfpState.isSfpValid
            });
        };
        _this.showValidation = function () {
            _this.sfp.showValidation();
        };
        _this.handleFocus = function (e) {
            _this.setState({ focusedElement: e.currentFocusObject });
            var isFocused = e.focus === true;
            if (isFocused) {
                _this.props.onFocus(e);
            }
            else {
                _this.props.onBlur(e);
            }
        };
        _this.handleSecuredFieldsRef = function (ref) {
            _this.sfp = ref;
        };
        return _this;
    }
    Giftcard.prototype.setStatus = function (status) {
        this.setState({ status: status });
    };
    Giftcard.prototype.render = function (props, _a) {
        var focusedElement = _a.focusedElement;
        var i18n = useCoreContext().i18n;
        return (h("div", { className: "adyen-checkout__giftcard" },
            h(SecuredFieldsProvider, __assign({}, this.props, { ref: this.handleSecuredFieldsRef, onChange: this.onChange, onFocus: this.handleFocus, type: 'giftcard', render: function (_a, sfpState) {
                    var setRootNode = _a.setRootNode, setFocusOn = _a.setFocusOn;
                    return (h("div", { ref: setRootNode, className: "adyen-checkout__field-wrapper" },
                        h(Field, { label: i18n.get('creditCard.numberField.title'), classNameModifiers: __spreadArrays(['number'], (props.pinRequired ? ['70'] : ['100'])), errorMessage: sfpState.errors.encryptedCardNumber && i18n.get('creditCard.numberField.invalid'), focused: focusedElement === 'encryptedCardNumber', onFocusField: function () { return setFocusOn('encryptedCardNumber'); } },
                            h("span", { "data-cse": "encryptedCardNumber", "data-info": '{"length":"15-22", "maskInterval":4}', className: classNames({
                                    'adyen-checkout__input': true,
                                    'adyen-checkout__input--large': true,
                                    'adyen-checkout__card__cardNumber__input': true,
                                    'adyen-checkout__input--error': sfpState.errors.encryptedCardNumber,
                                    'adyen-checkout__input--focus': focusedElement === 'encryptedCardNumber'
                                }) })),
                        props.pinRequired && (h(Field, { label: i18n.get('creditCard.pin.title'), classNameModifiers: ['pin', '30'], errorMessage: sfpState.errors.encryptedSecurityCode, focused: focusedElement === 'encryptedSecurityCode', onFocusField: function () { return setFocusOn('encryptedSecurityCode'); } },
                            h("span", { "data-cse": "encryptedSecurityCode", "data-info": '{"length":"3-10", "maskInterval": 0}', className: classNames({
                                    'adyen-checkout__input': true,
                                    'adyen-checkout__input--large': true,
                                    'adyen-checkout__card__cvc__input': true,
                                    'adyen-checkout__input--error': sfpState.errors.encryptedCardNumber,
                                    'adyen-checkout__input--focus': focusedElement === 'encryptedSecurityCode'
                                }) })))));
                } })),
            this.props.showPayButton && this.props.payButton({ status: this.state.status, label: i18n.get('applyGiftcard') })));
    };
    Giftcard.defaultProps = {
        pinRequired: true,
        onChange: function () { },
        onFocus: function () { },
        onBlur: function () { }
    };
    return Giftcard;
}(Component));
export default Giftcard;
