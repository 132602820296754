import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import DragonpayInput from './components/DragonpayInput';
import DragonpayVoucherResult from './components/DragonpayVoucherResult';
import CoreProvider from '../../core/Context/CoreProvider';
var DragonpayElement = /** @class */ (function (_super) {
    __extends(DragonpayElement, _super);
    function DragonpayElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DragonpayElement.prototype, "isValid", {
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @private
     * Formats the component data output
     * @return {object} props
     */
    DragonpayElement.prototype.formatData = function () {
        return {
            paymentMethod: __assign({ type: this.props.type || DragonpayElement.type }, this.state.data)
        };
    };
    DragonpayElement.prototype.formatProps = function (props) {
        return __assign(__assign({}, props), { items: props.details && props.details.length ? (props.details.find(function (d) { return d.key === 'issuer'; }) || {}).items : props.items });
    };
    DragonpayElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext }, this.props.reference ? (h(DragonpayVoucherResult, __assign({ ref: function (ref) {
                _this.componentRef = ref;
            }, icon: this.icon }, this.props))) : (h(DragonpayInput, __assign({ ref: function (ref) {
                _this.componentRef = ref;
            } }, this.props, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton })))));
    };
    DragonpayElement.type = 'dragonpay';
    return DragonpayElement;
}(UIElement));
export { DragonpayElement };
export default DragonpayElement;
