/**
 * Pads a given string or number with zeros.
 *
 * @param {any} value Value to zero-pad.
 * @param {number} [length=2] Amount of characters to pad.
 * @returns Left-padded number/string.
 */
export var zeroPad = function (value, length) {
    if (length === void 0) { length = 2; }
    if (length === 0)
        return value;
    var strValue = String(value);
    return strValue.length >= length ? strValue : ('0'.repeat(length) + strValue).slice(length * -1);
};
/**
 * Calculates the remaining time as a percentage
 *
 * @param {Date} start Start date
 * @param {Date} now Current date
 * @param {Date} end End date
 * @returns {Integer} Percentage of the remaining time
 */
export var getProgressPercentage = function (start, now, end) {
    var difference = end.getTime() - start.getTime();
    return 100 - Math.round(((now.getTime() - start.getTime()) * 100) / difference);
};
/**
 * Calculates the difference in minutes and seconds from now to endDate
 *
 * @param {Date} endDate
 * @returns {object} Time difference
 */
export var getTimeDifference = function (startTime, endTime) {
    var now = new Date();
    var diff = endTime.getTime() - now.getTime();
    var seconds = diff / 1000;
    var percentage = getProgressPercentage(startTime, now, endTime);
    return {
        total: diff,
        minutes: zeroPad(Math.floor((seconds / 60) % 60)),
        seconds: zeroPad(Math.floor(seconds % 60)),
        completed: diff <= 0,
        percentage: percentage
    };
};
