import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import BoletoInput from './components/BoletoInput';
import { cleanCPFCNPJ } from './components/BoletoInput/utils';
import BoletoVoucherResult from './components/BoletoVoucherResult';
import CoreProvider from '~/core/Context/CoreProvider';
var BoletoElement = /** @class */ (function (_super) {
    __extends(BoletoElement, _super);
    function BoletoElement() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleRef = function (ref) {
            _this.componentRef = ref;
        };
        return _this;
    }
    Object.defineProperty(BoletoElement.prototype, "isValid", {
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @private
     * Formats the component data output
     * @return {object} props
     */
    BoletoElement.prototype.formatData = function () {
        var _a = this.state.data, data = _a === void 0 ? {} : _a;
        var billingAddress = data.billingAddress, shopperEmail = data.shopperEmail, firstName = data.firstName, lastName = data.lastName, _b = data.socialSecurityNumber, socialSecurityNumber = _b === void 0 ? '' : _b;
        return __assign(__assign(__assign(__assign({ paymentMethod: {
                type: this.props.type || BoletoElement.type
            } }, (billingAddress && { billingAddress: billingAddress })), (shopperEmail && { shopperEmail: shopperEmail })), (firstName && lastName && { shopperName: { firstName: firstName, lastName: lastName } })), (socialSecurityNumber && { socialSecurityNumber: cleanCPFCNPJ(socialSecurityNumber) }));
    };
    BoletoElement.prototype.render = function () {
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext }, this.props.reference ? (h(BoletoVoucherResult, __assign({ ref: this.handleRef, icon: this.icon }, this.props))) : (h(BoletoInput, __assign({ ref: this.handleRef }, this.props, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton })))));
    };
    BoletoElement.type = 'boletobancario';
    return BoletoElement;
}(UIElement));
export { BoletoElement };
export default BoletoElement;
