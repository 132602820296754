import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import BlikInput from '~/components/Blik/components/BlikInput';
import Await from '../internal/Await';
import CoreProvider from '~/core/Context/CoreProvider';
import config from './config';
import RedirectButton from '~/components/internal/RedirectButton';
var BlikElement = /** @class */ (function (_super) {
    __extends(BlikElement, _super);
    function BlikElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BlikElement.prototype.formatData = function () {
        var recurringPayment = !!this.props.storedPaymentMethodId;
        return {
            paymentMethod: __assign(__assign({ type: BlikElement.type }, (!recurringPayment && { blikCode: this.state.data.blikCode })), (recurringPayment && { storedPaymentMethodId: this.props.storedPaymentMethodId }))
        };
    };
    Object.defineProperty(BlikElement.prototype, "isValid", {
        get: function () {
            if (this.props.storedPaymentMethodId) {
                return true;
            }
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    BlikElement.prototype.render = function () {
        var _this = this;
        if (this.props.paymentData) {
            return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
                h(Await, { ref: function (ref) {
                        _this.componentRef = ref;
                    }, loadingContext: this.props.loadingContext, originKey: this.props.originKey, clientKey: this.props.clientKey, paymentData: this.props.paymentData, onError: this.props.onError, onComplete: this.onComplete, brandLogo: this.icon, type: config.type, messageText: this.props.i18n.get(config.messageTextId), awaitText: this.props.i18n.get(config.awaitTextId), showCountdownTimer: config.showCountdownTimer, delay: config.STATUS_INTERVAL, countdownTime: config.COUNTDOWN_MINUTES, throttleTime: config.THROTTLE_TIME, throttleInterval: config.THROTTLE_INTERVAL })));
        }
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext }, this.props.storedPaymentMethodId ? (h(RedirectButton, { name: this.displayName, amount: this.props.amount, payButton: this.payButton, onSubmit: this.submit, ref: function (ref) {
                _this.componentRef = ref;
            } })) : (h(BlikInput, __assign({ ref: function (ref) {
                _this.componentRef = ref;
            } }, this.props, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton })))));
    };
    BlikElement.type = 'blik';
    return BlikElement;
}(UIElement));
export default BlikElement;
