import { __assign } from "tslib";
import Language from '../language';
import UIElement from '../components/UIElement';
import RiskModule from './RiskModule';
import paymentMethods, { getComponentConfiguration } from '../components';
import PaymentMethodsResponse from './ProcessResponse/PaymentMethodsResponse';
import getComponentForAction from './ProcessResponse/PaymentAction';
import resolveEnvironment from './Environment';
import Analytics from './Analytics';
var Core = /** @class */ (function () {
    function Core(options) {
        if (options === void 0) { options = {}; }
        this.options = __assign(__assign({}, options), { loadingContext: resolveEnvironment(options.environment) });
        this.modules = {
            risk: new RiskModule(this.options),
            analytics: new Analytics(this.options),
            i18n: new Language(options.locale, options.translations)
        };
        this.paymentMethodsResponse = new PaymentMethodsResponse(options.paymentMethodsResponse, options);
        this.create = this.create.bind(this);
        this.createFromAction = this.createFromAction.bind(this);
    }
    Core.prototype.create = function (paymentMethod, options) {
        var props = this.getPropsForComponent(options);
        return paymentMethod ? this.handleCreate(paymentMethod, props) : this.handleCreateError();
    };
    /**
     * Instantiates a new element component ready to be mounted from an action object
     * @param {PaymentAction} action action defining the component with the component data
     * @param {object} options options that will be merged to the global Checkout props
     * @return {object} new UIElement
     */
    Core.prototype.createFromAction = function (action, options) {
        if (options === void 0) { options = {}; }
        if (action.type) {
            var props = this.getPropsForComponent(options);
            return getComponentForAction(action, props);
        }
        return this.handleCreateError();
    };
    /**
     * @private
     * @param {object} options options that will be merged to the global Checkout props
     * @return {object} props for a new UIElement
     */
    Core.prototype.getPropsForComponent = function (options) {
        return __assign(__assign(__assign({ paymentMethods: this.paymentMethodsResponse.paymentMethods, storedPaymentMethods: this.paymentMethodsResponse.storedPaymentMethods }, this.options), options), { i18n: this.modules.i18n, modules: this.modules, createFromAction: this.createFromAction });
    };
    /**
     * @private
     */
    Core.prototype.handleCreate = function (PaymentMethod, options) {
        if (options === void 0) { options = {}; }
        var isValidClass = PaymentMethod.prototype instanceof UIElement;
        if (isValidClass) {
            var paymentMethodsDetails = !options.supportedShopperInteractions ? this.paymentMethodsResponse.find(PaymentMethod.type) : [];
            var paymentMethodsConfiguration = getComponentConfiguration(PaymentMethod.type, options.paymentMethodsConfiguration);
            return new PaymentMethod(__assign(__assign(__assign({}, paymentMethodsDetails), options), paymentMethodsConfiguration));
        }
        if (typeof PaymentMethod === 'string' && paymentMethods[PaymentMethod]) {
            return this.handleCreate(paymentMethods[PaymentMethod], options);
        }
        if (typeof PaymentMethod === 'string' &&
            this.paymentMethodsResponse.has(PaymentMethod) &&
            !this.paymentMethodsResponse.find(PaymentMethod).details) {
            var paymentMethodsConfiguration = getComponentConfiguration(PaymentMethod, options.paymentMethodsConfiguration);
            return this.handleCreate(paymentMethods.redirect, __assign(__assign(__assign({}, this.paymentMethodsResponse.find(PaymentMethod)), options), paymentMethodsConfiguration));
        }
        return this.handleCreateError(PaymentMethod);
    };
    /**
     * @private
     */
    Core.prototype.handleCreateError = function (paymentMethod) {
        var paymentMethodName = paymentMethod && paymentMethod.name ? paymentMethod.name : 'The passed payment method';
        var errorMessage = paymentMethod ? paymentMethodName + " is not a valid Checkout Component" : 'No Payment Method component was passed';
        throw new Error(errorMessage);
    };
    Core.version = {
        version: process.env.VERSION,
        revision: process.env.COMMIT_HASH,
        branch: process.env.COMMIT_BRANCH,
        buildId: process.env.ADYEN_BUILD_ID
    };
    return Core;
}());
export default Core;
