import { h } from 'preact';
import Voucher from '../../../internal/Voucher';
import getImage from '../../../../utils/get-image';
import useCoreContext from '~/core/Context/useCoreContext';
var DokuVoucherResult = function (props) {
    var reference = props.reference, expiresAt = props.expiresAt, instructionsUrl = props.instructionsUrl, shopperName = props.shopperName, merchantName = props.merchantName, totalAmount = props.totalAmount, paymentMethodType = props.paymentMethodType;
    var _a = useCoreContext(), loadingContext = _a.loadingContext, i18n = _a.i18n;
    return (h(Voucher, { paymentMethodType: paymentMethodType, reference: reference, introduction: i18n.get('voucher.introduction.doku'), imageUrl: getImage({ loadingContext: loadingContext })(paymentMethodType), instructionsUrl: instructionsUrl, amount: totalAmount && i18n.amount(totalAmount.value, totalAmount.currency), voucherDetails: [
            { label: i18n.get('voucher.expirationDate'), value: i18n.date(expiresAt) },
            { label: i18n.get('voucher.shopperName'), value: shopperName },
            { label: i18n.get('voucher.merchantName'), value: merchantName }
        ], copyBtn: true }));
};
export default DokuVoucherResult;
