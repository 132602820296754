import { h } from 'preact';
import { getImageUrl } from '~/utils/get-image';
import Img from '~/components/internal/Img';
import useCoreContext from '~/core/Context/useCoreContext';
var Success = function (_a) {
    var message = _a.message;
    var _b = useCoreContext(), i18n = _b.i18n, loadingContext = _b.loadingContext;
    return (h("div", { className: "adyen-checkout__status adyen-checkout__status--success" },
        h(Img, { height: "88", className: "adyen-checkout__status__icon", src: getImageUrl({ loadingContext: loadingContext, imageFolder: 'components/' })('success'), alt: i18n.get(message || 'creditCard.success') }),
        h("span", { className: "adyen-checkout__status__text" }, i18n.get(message || 'creditCard.success'))));
};
export default Success;
