import { __rest } from "tslib";
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { renderFormField } from '../FormFields';
import Field from '../FormFields/Field';
import useCoreContext from '~/core/Context/useCoreContext';
import './IssuerList.scss';
var payButtonLabel = function (_a, i18n) {
    var issuer = _a.issuer, items = _a.items;
    if (!issuer)
        return i18n.get('continue');
    var issuerName = items.find(function (i) { return i.id === issuer; }).name;
    return i18n.get('continueTo') + " " + issuerName;
};
function IssuerList(_a) {
    var items = _a.items, _b = _a.issuer, issuer = _b === void 0 ? null : _b, props = __rest(_a, ["items", "issuer"]);
    var i18n = useCoreContext().i18n;
    var _c = useState(issuer), selectedIssuer = _c[0], setSelectedIssuer = _c[1];
    var _d = useState(false), errors = _d[0], setErrors = _d[1];
    var _e = useState('ready'), status = _e[0], setStatus = _e[1];
    this.setStatus = function (newStatus) {
        setStatus(newStatus);
    };
    var onSelectIssuer = function (e) {
        var newIssuer = e.currentTarget.getAttribute('data-value');
        setSelectedIssuer(newIssuer);
        setErrors(false);
    };
    useEffect(function () {
        props.onChange({ issuer: selectedIssuer });
    }, [selectedIssuer]);
    this.showValidation = function () {
        setErrors(!selectedIssuer);
    };
    return (h("div", { className: "adyen-checkout__issuer-list" },
        h(Field, { errorMessage: errors, classNameModifiers: ['issuer-list'] }, renderFormField('select', {
            items: items,
            selected: selectedIssuer,
            placeholder: i18n.get('idealIssuer.selectField.placeholder'),
            name: 'issuer',
            className: 'adyen-checkout__issuer-list__dropdown',
            onChange: onSelectIssuer
        })),
        props.showPayButton && props.payButton({ status: status, label: payButtonLabel({ issuer: selectedIssuer, items: items }, i18n) })));
}
IssuerList.defaultProps = {
    onChange: function () { }
};
export default IssuerList;
