import { __assign, __extends } from "tslib";
import { h } from 'preact';
import CoreProvider from '~/core/Context/CoreProvider';
import RedirectElement from '~/components/Redirect';
import RedirectButton from '../internal/RedirectButton';
var GiropayElement = /** @class */ (function (_super) {
    __extends(GiropayElement, _super);
    function GiropayElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @private
     * Formats the component data output
     * @return {object} props
     */
    GiropayElement.prototype.formatData = function () {
        return {
            paymentMethod: {
                type: GiropayElement.type
            }
        };
    };
    Object.defineProperty(GiropayElement.prototype, "displayName", {
        get: function () {
            return this.props.name || this.constructor['type'];
        },
        enumerable: false,
        configurable: true
    });
    GiropayElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
            h(RedirectButton, __assign({}, this.props, { name: this.displayName, onSubmit: this.submit, payButton: this.payButton, ref: function (ref) {
                    _this.componentRef = ref;
                } }))));
    };
    GiropayElement.type = 'giropay';
    return GiropayElement;
}(RedirectElement));
export default GiropayElement;
