import { __assign } from "tslib";
import CURRENCY_CODES from './constants/currency-codes';
import CURRENCY_DECIMALS from './constants/currency-decimals';
/**
 * @private
 * @param {string} currencyCode
 * @returns {number}
 * Get divider amount
 */
export var getDivider = function (currencyCode) { return CURRENCY_DECIMALS[currencyCode] || 100; };
/**
 * @private
 * @param {string} currencyCode
 * @returns {boolean}
 * Returns whether a CURRENCY CODE is valid
 */
export var isValidCurrencyCode = function (currencyCode) { return !!CURRENCY_CODES[currencyCode]; };
/**
 * @private
 */
export var getCurrencyCode = function (currencyCode) { return (isValidCurrencyCode(currencyCode) ? CURRENCY_CODES[currencyCode] : false); };
/**
 * @private
 */
export var getDecimalAmount = function (amount, currencyCode) {
    var divider = getDivider(currencyCode);
    return parseInt(String(amount), 10) / divider;
};
/**
 * @private
 */
export var getLocalisedAmount = function (amount, locale, currencyCode, options) {
    if (options === void 0) { options = {}; }
    var stringAmount = amount.toString(); // Changing amount to string to avoid 0-value from returning false
    var decimalAmount = getDecimalAmount(stringAmount, currencyCode);
    var formattedLocale = locale.replace('_', '-');
    var localeOptions = __assign({ style: 'currency', currency: currencyCode, currencyDisplay: 'symbol' }, options);
    try {
        return decimalAmount.toLocaleString(formattedLocale, localeOptions);
    }
    catch (e) {
        return stringAmount;
    }
};
/**
 * @private
 */
export var getLocalisedPercentage = function (percent, locale) {
    if (percent === void 0) { percent = 0; }
    var decimalPercent = percent / 100 / 100;
    var localeOptions = {
        style: 'percent',
        maximumFractionDigits: 2
    };
    try {
        return decimalPercent.toLocaleString(locale, localeOptions);
    }
    catch (e) {
        return null;
    }
};
