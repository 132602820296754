import { __assign } from "tslib";
import { h } from 'preact';
import withOpenInvoice from '../helpers/withOpenInvoice';
import ConsentCheckbox from './components/ConsentCheckbox';
var AfterPay = withOpenInvoice({
    type: 'afterpay_default',
    // eslint-disable-next-line react/display-name
    consentCheckbox: function (props) { return h(ConsentCheckbox, __assign({}, props)); }
});
export default AfterPay;
