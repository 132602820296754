import { __spreadArrays } from "tslib";
import { h } from 'preact';
import cx from 'classnames';
import useCoreContext from '~/core/Context/useCoreContext';
import './Fieldset.scss';
export default function Fieldset(_a) {
    var children = _a.children, _b = _a.classNameModifiers, classNameModifiers = _b === void 0 ? [] : _b, label = _a.label, _c = _a.readonly, readonly = _c === void 0 ? false : _c;
    var i18n = useCoreContext().i18n;
    return (h("div", { className: cx(__spreadArrays([
            'adyen-checkout__fieldset'
        ], classNameModifiers.map(function (m) { return "adyen-checkout__fieldset--" + m; }), [
            { 'adyen-checkout__fieldset--readonly': readonly }
        ])) },
        label && h("div", { className: "adyen-checkout__fieldset__title" }, i18n.get(label)),
        h("div", { className: "adyen-checkout__fieldset__fields" }, children)));
}
