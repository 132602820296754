import { __assign } from "tslib";
import { h } from 'preact';
import cx from 'classnames';
import './RadioGroup.scss';
export default function RadioGroup(props) {
    var items = props.items, i18n = props.i18n, onChange = props.onChange, value = props.value, isInvalid = props.isInvalid;
    return (h("div", { className: "adyen-checkout__radio_group" }, items.map(function (item) { return (h("label", { key: item.id, className: "adyen-checkout__radio_group__input-wrapper" },
        h("input", __assign({}, props, { type: "radio", checked: value === item.id, className: "adyen-checkout__radio_group__input", onChange: onChange, onClick: onChange, value: item.id })),
        h("span", { className: cx([
                'adyen-checkout__label__text',
                'adyen-checkout__radio_group__label',
                props.className,
                { 'adyen-checkout__radio_group__label--invalid': isInvalid }
            ]) }, i18n.get(item.name)))); })));
}
RadioGroup.defaultProps = {
    onChange: function () { },
    items: []
};
