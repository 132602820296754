import { __assign, __spreadArrays } from "tslib";
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Fieldset from '../FormFields/Fieldset';
import Field from '../FormFields/Field';
import ReadOnlyAddress from './components/ReadOnlyAddress';
import StateField from './components/StateField';
import CountryField from './components/CountryField';
import { renderFormField } from '../FormFields';
import { addressValidationRules } from './validate';
import Validator from '~/utils/Validator';
import useCoreContext from '~/core/Context/useCoreContext';
import { COUNTRIES_WITH_STATES_DATASET } from '~/components/internal/Address/constants';
var addressSchema = ['street', 'houseNumberOrName', 'postalCode', 'city', 'stateOrProvince', 'country'];
export default function Address(props) {
    var i18n = useCoreContext().i18n;
    var _a = props.label, label = _a === void 0 ? '' : _a, requiredFields = props.requiredFields, visibility = props.visibility;
    var validator = new Validator(addressValidationRules);
    var addressReducer = function (acc, cur) {
        acc[cur] = props.data[cur] || (requiredFields.includes(cur) ? '' : 'N/A');
        return acc;
    };
    var _b = useState(addressSchema.reduce(addressReducer, {})), data = _b[0], setData = _b[1];
    var _c = useState({}), errors = _c[0], setErrors = _c[1];
    var _d = useState({}), valid = _d[0], setValid = _d[1];
    var handleChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var isValid = validator.validate(name, 'blur')(value);
        setData(function (data) {
            var _a;
            return (__assign(__assign({}, data), (_a = {}, _a[name] = value, _a)));
        });
        setValid(function (valid) {
            var _a;
            return (__assign(__assign({}, valid), (_a = {}, _a[name] = isValid, _a)));
        });
        setErrors(function (errors) {
            var _a;
            return (__assign(__assign({}, errors), (_a = {}, _a[name] = !isValid, _a)));
        });
    };
    var handleStateChange = function (e) {
        var field = e.currentTarget;
        var value = field.getAttribute('data-value');
        setData(function (data) { return (__assign(__assign({}, data), { stateOrProvince: value })); });
        setValid(function (valid) { return (__assign(__assign({}, valid), { stateOrProvince: !!value })); });
        setErrors(function (errors) { return (__assign(__assign({}, errors), { stateOrProvince: !value })); });
    };
    var handleCountryChange = function (e) {
        var field = e.currentTarget;
        var value = field.getAttribute('data-value');
        var stateOrProvince = COUNTRIES_WITH_STATES_DATASET.includes(value) ? '' : 'N/A';
        setData(function (data) { return (__assign(__assign({}, data), { stateOrProvince: stateOrProvince, country: value })); });
        setValid(function (valid) { return (__assign(__assign({}, valid), { country: !!value })); });
        setErrors(function (errors) { return (__assign(__assign({}, errors), { country: !value })); });
    };
    useEffect(function () {
        var stateFieldIsRequired = requiredFields.includes('stateOrProvince');
        var countryHasStatesDataset = data.country && COUNTRIES_WITH_STATES_DATASET.includes(data.country);
        var addressShouldHaveState = stateFieldIsRequired && countryHasStatesDataset;
        var stateOrProvince = data.stateOrProvince || (addressShouldHaveState ? '' : 'N/A');
        setData(function (data) { return (__assign(__assign({}, data), { stateOrProvince: stateOrProvince })); });
    }, []);
    useEffect(function () {
        var isValid = requiredFields.every(function (field) { return validator.validate(field, 'blur')(data[field]); });
        props.onChange({ data: data, isValid: isValid });
    }, [data, valid, errors]);
    this.showValidation = function () {
        var errorsReducer = function (acc, cur) {
            acc[cur] = !validator.validate(cur, 'blur')(data[cur]);
            return acc;
        };
        setErrors(requiredFields.reduce(errorsReducer, {}));
    };
    if (visibility === 'hidden')
        return null;
    if (visibility === 'readOnly')
        return h(ReadOnlyAddress, { data: data, label: label });
    return (h(Fieldset, { classNameModifiers: [label], label: label },
        requiredFields.includes('street') && (h(Field, { label: i18n.get('street'), classNameModifiers: __spreadArrays((requiredFields.includes('houseNumberOrName') ? ['col-70'] : []), ['street']), errorMessage: !!errors.street }, renderFormField('text', {
            name: 'street',
            value: data.street,
            classNameModifiers: ['street'],
            onInput: handleChange
        }))),
        requiredFields.includes('houseNumberOrName') && (h(Field, { label: i18n.get('houseNumberOrName'), classNameModifiers: ['col-30', 'houseNumberOrName'], errorMessage: !!errors.houseNumberOrName }, renderFormField('text', {
            name: 'houseNumberOrName',
            value: data.houseNumberOrName,
            classNameModifiers: ['houseNumberOrName'],
            onInput: handleChange
        }))),
        h("div", { className: "adyen-checkout__field-group" },
            requiredFields.includes('postalCode') && (h(Field, { label: i18n.get('postalCode'), classNameModifiers: ['postalCode', 'col-30'], errorMessage: !!errors.postalCode }, renderFormField('text', {
                name: 'postalCode',
                value: data.postalCode,
                classNameModifiers: ['postalCode'],
                onInput: handleChange
            }))),
            requiredFields.includes('city') && (h(Field, { label: i18n.get('city'), classNameModifiers: ['city', 'col-70'], errorMessage: !!errors.city }, renderFormField('text', {
                name: 'city',
                value: data.city,
                classNameModifiers: ['city'],
                onInput: handleChange
            })))),
        requiredFields.includes('country') && (h(CountryField, { value: data.country, errorMessage: !!errors.country, onDropdownChange: handleCountryChange, allowedCountries: props.allowedCountries })),
        requiredFields.includes('stateOrProvince') && (h(StateField, { value: data.stateOrProvince, errorMessage: !!errors.stateOrProvince, country: data.country, onDropdownChange: handleStateChange }))));
}
Address.defaultProps = {
    data: {},
    onChange: function () { },
    visibility: 'editable',
    requiredFields: addressSchema,
    countryCode: null
};
