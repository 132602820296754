import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import DokuInput from './components/DokuInput';
import DokuVoucherResult from './components/DokuVoucherResult';
import CoreProvider from '../../core/Context/CoreProvider';
import getImage from '../../utils/get-image';
var DokuElement = /** @class */ (function (_super) {
    __extends(DokuElement, _super);
    function DokuElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DokuElement.prototype, "isValid", {
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @private
     * Formats the component data output
     * @return {object} props
     */
    DokuElement.prototype.formatData = function () {
        return {
            paymentMethod: __assign({ type: this.props.type || DokuElement.type }, this.state.data)
        };
    };
    Object.defineProperty(DokuElement.prototype, "icon", {
        get: function () {
            return getImage({ loadingContext: this.props.loadingContext })(this.props.type);
        },
        enumerable: false,
        configurable: true
    });
    DokuElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext }, this.props.reference ? (h(DokuVoucherResult, __assign({ ref: function (ref) {
                _this.componentRef = ref;
            } }, this.props))) : (h(DokuInput, __assign({ ref: function (ref) {
                _this.componentRef = ref;
            } }, this.props, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton })))));
    };
    DokuElement.type = 'doku';
    return DokuElement;
}(UIElement));
export { DokuElement };
export default DokuElement;
