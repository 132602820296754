import { h } from 'preact';
import Voucher from '../../../internal/Voucher';
import getImage from '../../../../utils/get-image';
import useCoreContext from '~/core/Context/useCoreContext';
var EcontextVoucherResult = function (props) {
    var reference = props.reference, totalAmount = props.totalAmount, expiresAt = props.expiresAt, paymentMethodType = props.paymentMethodType, maskedTelephoneNumber = props.maskedTelephoneNumber, instructionsUrl = props.instructionsUrl;
    var _a = useCoreContext(), loadingContext = _a.loadingContext, i18n = _a.i18n;
    return (h(Voucher, { paymentMethodType: paymentMethodType, reference: reference, introduction: i18n.get('voucher.introduction.econtext'), imageUrl: getImage({ loadingContext: loadingContext })(paymentMethodType), instructionsUrl: instructionsUrl, amount: totalAmount && i18n.amount(totalAmount.value, totalAmount.currency), voucherDetails: [
            { label: i18n.get('voucher.expirationDate'), value: i18n.date(expiresAt) },
            { label: i18n.get('voucher.telephoneNumber'), value: maskedTelephoneNumber }
        ], copyBtn: true }));
};
export default EcontextVoucherResult;
