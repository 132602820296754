export var processErrors = function (pFeedbackObj, securedField, type, rootNode, callbackFn) {
    if (!Object.prototype.hasOwnProperty.call(pFeedbackObj, 'error'))
        return null;
    var fieldType = pFeedbackObj.fieldType;
    var field = securedField;
    // Initialise error callback object
    var dataObj = { rootNode: rootNode, fieldType: fieldType, error: null, type: null };
    var isError = pFeedbackObj.error !== '';
    // Error is empty string && field is not already in error - do nothing
    // This situation arises when we encrypt a field and trigger an "error clearing" event
    if (!isError && !field.hasError)
        return null;
    dataObj.error = isError ? pFeedbackObj.error : '';
    dataObj.type = type;
    // Set error state & type on securedField instance
    field.hasError = isError;
    field.errorType = dataObj.error;
    // logger.log('### processErrors::processErrors:: ',fieldType,'errorType=',securedField.errorType);
    callbackFn(dataObj);
    return dataObj;
};
