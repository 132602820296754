import { __extends } from "tslib";
import { Component, h } from 'preact';
import classNames from 'classnames';
import PaymentMethodItem from './PaymentMethodItem';
import getProp from '~/utils/getProp';
import styles from '../DropinComponent.module.scss';
var PaymentMethodList = /** @class */ (function (_super) {
    __extends(PaymentMethodList, _super);
    function PaymentMethodList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onSelect = function (paymentMethod) { return function () { return _this.props.onSelect(paymentMethod); }; };
        return _this;
    }
    PaymentMethodList.prototype.componentDidMount = function () {
        // Open first PaymentMethodItem
        if (this.props.paymentMethods[0]) {
            var firstPaymentMethod = this.props.paymentMethods[0];
            var shouldOpenFirstStored = this.props.openFirstStoredPaymentMethod && getProp(firstPaymentMethod, 'props.oneClick') === true;
            var shouldOpenFirstPaymentMethod = shouldOpenFirstStored || this.props.openFirstPaymentMethod;
            if (shouldOpenFirstPaymentMethod) {
                this.onSelect(firstPaymentMethod)();
            }
        }
    };
    PaymentMethodList.prototype.render = function (_a) {
        var _b;
        var _this = this;
        var paymentMethods = _a.paymentMethods, activePaymentMethod = _a.activePaymentMethod, cachedPaymentMethods = _a.cachedPaymentMethods, isLoading = _a.isLoading;
        var paymentMethodListClassnames = classNames((_b = {},
            _b[styles['adyen-checkout__payment-methods-list']] = true,
            _b['adyen-checkout__payment-methods-list'] = true,
            _b['adyen-checkout__payment-methods-list--loading'] = isLoading,
            _b));
        return (h("ul", { className: paymentMethodListClassnames }, paymentMethods.map(function (paymentMethod, index, paymentMethodsCollection) {
            var isSelected = activePaymentMethod && activePaymentMethod.props.id === paymentMethod.props.id;
            var isLoaded = paymentMethod.props.id in cachedPaymentMethods;
            var isNextOneSelected = activePaymentMethod &&
                paymentMethodsCollection[index + 1] &&
                activePaymentMethod.props.id === paymentMethodsCollection[index + 1].props.id;
            return (h(PaymentMethodItem, { className: classNames({ 'adyen-checkout__payment-method--next-selected': isNextOneSelected }), standalone: paymentMethods.length === 1, paymentMethod: paymentMethod, isSelected: isSelected, isDisabling: isSelected && _this.props.isDisabling, isLoaded: isLoaded, isLoading: isLoading, onSelect: _this.onSelect(paymentMethod), key: paymentMethod.props.id, showRemovePaymentMethodButton: _this.props.showRemovePaymentMethodButton, onDisableStoredPaymentMethod: _this.props.onDisableStoredPaymentMethod }));
        })));
    };
    PaymentMethodList.defaultProps = {
        paymentMethods: [],
        activePaymentMethod: null,
        cachedPaymentMethods: {},
        onSelect: function () { },
        onDisableStoredPaymentMethod: function () { },
        isDisabling: false,
        isLoading: false
    };
    return PaymentMethodList;
}(Component));
export default PaymentMethodList;
