import { __assign } from "tslib";
/**
 * Formats and returns the passed items, adds flag string
 * @param {object} item - prefix
 * @param {string} item.name - displayable name
 * @param {string} item.id - id required to be passed to back-end
 * @returns {object} item with added displayable name and image
 */
export var formatPrefixName = function (item) {
    if (!item) {
        throw new Error('No item passed');
    }
    if (!item.name || !item.id) {
        return false;
    }
    var flag = item.name.toUpperCase().replace(/./g, function (char) { return (String.fromCodePoint ? String.fromCodePoint(char.charCodeAt(0) + 127397) : ''); });
    return __assign(__assign({}, item), { name: flag + " " + item.name + " (" + item.id + ")" });
};
/** Finds id in list of items, returns it on match
 * @param {array} items - list of items
 * @param {string} countryCode - the item to select
 * @returns {string || boolean } - item or false
 */
export var selectItem = function (items, countryCode) {
    if (items && countryCode) {
        var item = items.find(function (i) { return i.name === countryCode; });
        if (item) {
            return item.id;
        }
        return false;
    }
    return false;
};
