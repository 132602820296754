import { h } from 'preact';
import Voucher from '~/components/internal/Voucher';
import getImage from '~/utils/get-image';
import useCoreContext from '~/core/Context/useCoreContext';
import './BoletoVoucherResult.scss';
var BoletoVoucherResult = function (props) {
    var _a = useCoreContext(), i18n = _a.i18n, loadingContext = _a.loadingContext;
    var reference = props.reference, expiresAt = props.expiresAt, totalAmount = props.totalAmount, paymentMethodType = props.paymentMethodType, downloadUrl = props.downloadUrl;
    var barcodeReference = reference.replace(/[^\d]/g, '').replace(/^(\d{4})(\d{5})\d{1}(\d{10})\d{1}(\d{10})\d{1}(\d{15})$/, '$1$5$2$3$4');
    var barcodeUrl = loadingContext + "barcode.shtml?data=" + barcodeReference + "&barcodeType=BT_Int2of5A&fileType=png";
    return (h(Voucher, { reference: reference, paymentMethodType: 'boletobancario', barcode: barcodeUrl, introduction: i18n.get('voucher.introduction'), imageUrl: getImage({ loadingContext: loadingContext })(paymentMethodType), amount: totalAmount && i18n.amount(totalAmount.value, totalAmount.currency), voucherDetails: [{ label: i18n.get('voucher.expirationDate'), value: i18n.date(expiresAt) }], downloadUrl: downloadUrl, copyBtn: true }));
};
export default BoletoVoucherResult;
