import { __assign, __extends, __rest } from "tslib";
import { Component, h } from 'preact';
import SelectListItem from './SelectListItem';
import './SelectList.scss';
var SelectList = /** @class */ (function (_super) {
    __extends(SelectList, _super);
    function SelectList(props) {
        var _this = _super.call(this, props) || this;
        _this.setState({ selected: _this.props.selected });
        _this.handleSelect = _this.handleSelect.bind(_this);
        return _this;
    }
    SelectList.prototype.handleSelect = function (item) {
        this.setState({ selected: item });
        this.props.onChange(item);
    };
    SelectList.prototype.render = function (_a) {
        var _this = this;
        var _b = _a.items, items = _b === void 0 ? [] : _b, _c = _a.optional, optional = _c === void 0 ? false : _c, props = __rest(_a, ["items", "optional"]);
        return (h("ul", __assign({ className: "adyen-checkout__select-list" }, props, { required: !optional }), items.map(function (item) { return (h(SelectListItem, { key: item.id, item: item, selected: _this.state.selected.id === item.id, onChange: _this.handleSelect, onClick: _this.handleClick })); })));
    };
    SelectList.defaultProps = {
        selected: {},
        onChange: function () { }
    };
    return SelectList;
}(Component));
export default SelectList;
