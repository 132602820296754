import { __assign, __extends } from "tslib";
import { Component, h } from 'preact';
import handlers from './handlers';
import Card from './components/Card';
import SecuredFieldsProvider from '~/components/internal/SecuredFields/SecuredFieldsProvider';
import Address from '~/components/internal/Address';
import OneClickCard from './components/OneClickCard';
import StoreDetails from '~/components/internal/StoreDetails';
import CardHolderName from './components/CardHolderName';
import LoadingWrapper from '../../../internal/LoadingWrapper/LoadingWrapper';
import KCPAuthentication from './components/KCPAuthentication';
import Installments from './components/Installments';
import defaultProps from './defaultProps';
import defaultStyles from './defaultStyles';
import styles from './CardInput.module.scss';
import getImage from '~/utils/get-image';
import './CardInput.scss';
import { renderFormField } from '~/components/internal/FormFields';
import Field from '~/components/internal/FormFields/Field';
import processBinLookupResponse from './processBinLookup';
var CardInput = /** @class */ (function (_super) {
    __extends(CardInput, _super);
    function CardInput(props) {
        var _this = _super.call(this, props) || this;
        _this.handleSecuredFieldsRef = function (ref) {
            _this.sfp = ref;
        };
        _this.handleBillingAddressRef = function (ref) {
            _this.billingAddressRef = ref;
        };
        _this.handleKCPAuthenticationRef = function (ref) {
            _this.kcpAuthenticationRef = ref;
        };
        _this.state = __assign(__assign({ status: 'ready', errors: {}, valid: __assign({}, (_this.props.holderNameRequired && { holderName: false })), data: __assign({}, (_this.props.hasHolderName && {
                holderName: _this.props.holderName || _this.props.data.holderName
            })) }, (_this.props.billingAddressRequired && {
            billingAddress: __assign({}, _this.props.data.billingAddress)
        })), { isValid: false, hideCVCForBrand: false, focusedElement: '', additionalSelectElements: [], additionalSelectValue: '', additionalSelectType: '' });
        _this.validateCardInput = handlers.validateCardInput.bind(_this);
        _this.handleOnBrand = handlers.handleOnBrand.bind(_this);
        _this.handleFocus = handlers.handleFocus.bind(_this);
        _this.handleAddress = handlers.handleAddress.bind(_this);
        _this.handleHolderName = handlers.handleHolderName.bind(_this);
        _this.handleInstallments = handlers.handleInstallments.bind(_this);
        _this.handleKCPAuthentication = handlers.handleKCPAuthentication.bind(_this);
        _this.handleSecuredFieldsChange = handlers.handleSecuredFieldsChange.bind(_this);
        _this.handleOnStoreDetails = handlers.handleOnStoreDetails.bind(_this);
        _this.handleAdditionalDataSelection = handlers.handleAdditionalDataSelection.bind(_this);
        _this.processBinLookupResponse = processBinLookupResponse;
        return _this;
    }
    CardInput.prototype.componentDidMount = function () {
        this.setFocusOn = this.sfp.setFocusOn;
        this.updateStyles = this.sfp.updateStyles;
    };
    CardInput.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (prevState.billingAddress && prevState.billingAddress.country !== this.state.billingAddress.country) {
            this.validateCardInput();
        }
    };
    CardInput.prototype.componentWillUnmount = function () {
        this.sfp.destroy();
        this.sfp = null;
    };
    CardInput.prototype.getChildContext = function () {
        return { i18n: this.props.i18n };
    };
    CardInput.prototype.setStatus = function (status) {
        this.setState({ status: status });
    };
    CardInput.prototype.resetAdditionalSelectState = function () {
        this.setState({
            additionalSelectElements: [],
            additionalSelectValue: '',
            additionalSelectType: ''
        });
    };
    CardInput.prototype.showValidation = function () {
        // Validate SecuredFields
        this.sfp.showValidation();
        // Validate holderName
        if (this.props.holderNameRequired && !this.state.valid.holderName) {
            this.setState(function (prevState) { return ({
                errors: __assign(__assign({}, prevState.errors), { holderName: true })
            }); });
        }
        // Validate Address
        if (this.billingAddressRef)
            this.billingAddressRef.showValidation();
        // Validate KCP authentication
        if (this.kcpAuthenticationRef)
            this.kcpAuthenticationRef.showValidation();
    };
    CardInput.prototype.render = function (_a, _b) {
        var _this = this;
        var loadingContext = _a.loadingContext, hasHolderName = _a.hasHolderName, hasCVC = _a.hasCVC, i18n = _a.i18n, installmentOptions = _a.installmentOptions, enableStoreDetails = _a.enableStoreDetails;
        var status = _b.status, hideCVCForBrand = _b.hideCVCForBrand, focusedElement = _b.focusedElement;
        var hasInstallments = !!Object.keys(installmentOptions).length;
        var isOneClick = this.props.storedPaymentMethodId ? true : false;
        if (this.props.oneClick === true)
            isOneClick = true; // In the Drop-in the oneClick status may already have been decided, so give that priority
        return (h(SecuredFieldsProvider, __assign({ ref: this.handleSecuredFieldsRef }, this.props, { styles: __assign(__assign({}, defaultStyles), this.props.styles), onChange: this.handleSecuredFieldsChange, onBrand: this.handleOnBrand, onFocus: this.handleFocus, type: this.props.brand, oneClick: isOneClick, render: function (_a, sfpState) {
                var setRootNode = _a.setRootNode, setFocusOn = _a.setFocusOn;
                return (h("div", { ref: setRootNode, className: "adyen-checkout__card-input " + styles['card-input__wrapper'] },
                    _this.props.storedPaymentMethodId ? (h(LoadingWrapper, { status: sfpState.status },
                        h(OneClickCard, __assign({}, _this.props, { cvcRequired: sfpState.cvcRequired, errors: sfpState.errors, brand: sfpState.brand, hasCVC: hasCVC, hideCVCForBrand: hideCVCForBrand, onFocusField: setFocusOn, focusedElement: focusedElement, status: sfpState.status, valid: sfpState.valid })),
                        hasInstallments && (h(Installments, { amount: _this.props.amount, brand: sfpState.brand, installmentOptions: installmentOptions, onChange: _this.handleInstallments })))) : (h(LoadingWrapper, { status: sfpState.status },
                        _this.state.additionalSelectElements.length > 0 && (h(Field, { label: i18n.get('Select variation'), classNameModifiers: ['txVariantAdditionalInfo'] }, renderFormField('select', {
                            name: 'selectAdditionalTXData',
                            onChange: _this.handleAdditionalDataSelection,
                            selected: _this.state.additionalSelectValue,
                            placeholder: i18n.get('Select variation'),
                            items: _this.state.additionalSelectElements,
                            readonly: false
                        }))),
                        h(Card, __assign({}, _this.props, { brand: sfpState.brand, focusedElement: focusedElement, onFocusField: setFocusOn, hasCVC: hasCVC, hideCVCForBrand: hideCVCForBrand, errors: sfpState.errors, valid: sfpState.valid, cvcRequired: sfpState.cvcRequired })),
                        hasHolderName && (h(CardHolderName, { required: _this.props.holderNameRequired, placeholder: _this.props.placeholders.holderName, value: _this.state.data.holderName, error: !!_this.state.errors.holderName, isValid: !!_this.state.valid.holderName, onChange: _this.handleHolderName })),
                        _this.props.koreanAuthenticationRequired && (h(KCPAuthentication, { onFocusField: setFocusOn, focusedElement: focusedElement, encryptedPasswordState: {
                                data: sfpState.encryptedPassword,
                                valid: sfpState.valid ? sfpState.valid.encryptedPassword : false,
                                errors: sfpState.errors ? sfpState.errors.encryptedPassword : false
                            }, ref: _this.handleKCPAuthenticationRef, onChange: _this.handleKCPAuthentication })),
                        enableStoreDetails && h(StoreDetails, { onChange: _this.handleOnStoreDetails }),
                        hasInstallments && (h(Installments, { amount: _this.props.amount, brand: sfpState.brand, installmentOptions: installmentOptions, onChange: _this.handleInstallments })),
                        _this.props.billingAddressRequired && (h(Address, { label: "billingAddress", data: _this.state.billingAddress, onChange: _this.handleAddress, allowedCountries: _this.props.billingAddressAllowedCountries, requiredFields: _this.props.billingAddressRequiredFields, ref: _this.handleBillingAddressRef })))),
                    _this.props.showPayButton &&
                        _this.props.payButton({ status: status, icon: getImage({ loadingContext: loadingContext, imageFolder: 'components/' })('lock') })));
            } })));
    };
    CardInput.defaultProps = defaultProps;
    return CardInput;
}(Component));
export default CardInput;
