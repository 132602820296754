//const emailRegEx = /\S+@\S+\.\S+/;
/* eslint-disable max-len */
var emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable max-len */
var phoneNumberRegEx = /^[+]*[0-9]{1,4}[\s/0-9]*$/;
export var mbwayValidationRules = {
    input: {
        email: function (email) {
            return { isValid: emailRegEx.test(email), value: email };
        },
        phoneNumber: function (num) {
            // Format
            var regEx = /[^0-9+\s]/g;
            var formattedVal = num.replace(regEx, '');
            // Validate
            var isValid = phoneNumberRegEx.test(formattedVal) && formattedVal && formattedVal.length >= 7;
            return { isValid: isValid, value: formattedVal };
        },
        default: function (value) { return value && value.length > 0; }
    },
    blur: {
        email: function (email) {
            return { isValid: emailRegEx.test(email), value: email };
        },
        phoneNumber: function (num) {
            // Just validate
            return { isValid: phoneNumberRegEx.test(num) && num && num.length >= 7, value: num };
        },
        default: function (value) { return value && value.length > 0; }
    }
};
