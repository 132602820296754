import { __spreadArrays } from "tslib";
import { h } from 'preact';
import Voucher from '~/components/internal/Voucher';
import getImage from '~/utils/get-image';
import useCoreContext from '~/core/Context/useCoreContext';
import './OxxoVoucherResult.scss';
var OxxoVoucherResult = function (props) {
    var _a = useCoreContext(), i18n = _a.i18n, loadingContext = _a.loadingContext;
    var alternativeReference = props.alternativeReference, reference = props.reference, expiresAt = props.expiresAt, merchantReference = props.merchantReference, totalAmount = props.totalAmount, paymentMethodType = props.paymentMethodType, downloadUrl = props.downloadUrl;
    var barcodeUrl = loadingContext + "barcode.shtml?data=" + reference + "&barcodeType=BT_Code128C&fileType=png";
    var voucherDetails = __spreadArrays((expiresAt
        ? [
            {
                label: i18n.get('voucher.expirationDate'),
                value: i18n.date(expiresAt)
            }
        ]
        : []), (merchantReference
        ? [
            {
                label: i18n.get('voucher.shopperReference'),
                value: merchantReference
            }
        ]
        : []), (alternativeReference
        ? [
            {
                label: i18n.get('voucher.alternativeReference'),
                value: alternativeReference
            }
        ]
        : []));
    return (h(Voucher, { amount: totalAmount && i18n.amount(totalAmount.value, totalAmount.currency), barcode: barcodeUrl, copyBtn: true, downloadUrl: downloadUrl, imageUrl: getImage({ loadingContext: loadingContext })(paymentMethodType), introduction: i18n.get('voucher.introduction'), paymentMethodType: 'oxxo', reference: reference, voucherDetails: voucherDetails }));
};
export default OxxoVoucherResult;
