import { __assign, __spreadArrays } from "tslib";
import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import { renderFormField } from '~/components/internal/FormFields';
import Field from '~/components/internal/FormFields/Field';
import Address from '~/components/internal/Address';
import Validator from '~/utils/Validator';
import { boletoValidationRules } from './validate';
import SendCopyToEmail from './SendCopyToEmail';
import { formatCPFCNPJ } from './utils';
import useCoreContext from '~/core/Context/useCoreContext';
var validator = new Validator(boletoValidationRules);
function BoletoInput(props) {
    var i18n = useCoreContext().i18n;
    var addressRef = useRef(null);
    var _a = useState(__assign(__assign({}, props.data), (props.data.socialSecurityNumber && { socialSecurityNumber: formatCPFCNPJ(props.data.socialSecurityNumber) }))), data = _a[0], setData = _a[1];
    var _b = useState({}), errors = _b[0], setErrors = _b[1];
    var _c = useState(__assign({}, (props.data.socialSecurityNumber && {
        socialSecurityNumber: validator.validate('socialSecurityNumber', 'input')(formatCPFCNPJ(this.props.data.socialSecurityNumber))
    }))), valid = _c[0], setValid = _c[1];
    var _d = useState(false), showingEmail = _d[0], setShowingEmail = _d[1];
    var toggleEmailField = function () { return setShowingEmail(!showingEmail); };
    var buttonModifiers = __spreadArrays((!props.personalDetailsRequired && !props.billingAddressRequired && !props.showEmailAddress ? ['standalone'] : []));
    var updateFieldData = function (key, value, isValid) {
        var _a, _b, _c;
        setData(__assign(__assign({}, data), (_a = {}, _a[key] = value, _a)));
        setValid(__assign(__assign({}, valid), (_b = {}, _b[key] = isValid, _b)));
        setErrors(__assign(__assign({}, errors), (_c = {}, _c[key] = !isValid, _c)));
    };
    var handleInputFor = function (key) { return function (e) {
        var value = e.target.value;
        var isValid = validator.validate(key, 'input')(value);
        updateFieldData(key, value, isValid);
    }; };
    var handleChangeFor = function (key) { return function (e) {
        var value = e.target.value;
        var isValid = validator.validate(key, 'blur')(value);
        updateFieldData(key, value, isValid);
    }; };
    var handleCPFInput = function (e) {
        var _a, _b, _c;
        var key = 'socialSecurityNumber';
        var value = formatCPFCNPJ(e.target.value);
        var isValid = validator.validate(key, 'input')(value);
        setData(__assign(__assign({}, data), (_a = {}, _a[key] = value, _a)));
        setValid(__assign(__assign({}, valid), (_b = {}, _b[key] = isValid, _b)));
        setErrors(__assign(__assign({}, errors), (_c = {}, _c[key] = false, _c)));
    };
    var handleAddress = function (address) {
        setData(__assign(__assign({}, data), { billingAddress: address.data }));
        setValid(__assign(__assign({}, valid), { billingAddress: address.isValid }));
    };
    this.showValidation = function () {
        setErrors(__assign(__assign({}, (showingEmail && { shopperEmail: !validator.validate('shopperEmail')(data.shopperEmail) })), (props.personalDetailsRequired && {
            firstName: !validator.validate('firstName')(data.firstName),
            lastName: !validator.validate('lastName')(data.lastName),
            socialSecurityNumber: !validator.validate('socialSecurityNumber')(data.socialSecurityNumber)
        })));
        if (props.billingAddressRequired) {
            addressRef.current.showValidation();
        }
    };
    useEffect(function () {
        var personalFields = ['firstName', 'lastName', 'socialSecurityNumber'];
        var personalFieldsValid = props.personalDetailsRequired
            ? personalFields.reduce(function (acc, field) { return acc && Boolean(validator.validate(field, 'blur')(data[field])); }, true)
            : true;
        var billingAddressValid = props.billingAddressRequired ? Boolean(valid.billingAddress) : true;
        var emailRequired = showingEmail && props.showEmailAddress;
        var emailAddressValid = emailRequired ? Boolean(validator.validate('shopperEmail', 'blur')(data.shopperEmail)) : true;
        var shopperEmail = emailRequired ? data.shopperEmail : null;
        var isValid = personalFieldsValid && billingAddressValid && emailAddressValid;
        props.onChange({ data: __assign(__assign({}, data), { shopperEmail: shopperEmail }), isValid: isValid });
    }, [data, valid, errors, showingEmail]);
    return (h("div", { className: "adyen-checkout__boleto-input__field" },
        props.personalDetailsRequired && (h("div", { className: 'adyen-checkout__fieldset adyen-checkout__fieldset--address adyen-checkout__fieldset--personalDetails' },
            h("div", { className: "adyen-checkout__fieldset__title" }, i18n.get('personalDetails')),
            h("div", { className: "adyen-checkout__fieldset__fields" },
                h(Field, { label: i18n.get('firstName'), classNameModifiers: ['firstName', 'col-50'], errorMessage: errors.firstName }, renderFormField('text', {
                    name: 'firstName',
                    autocorrect: 'off',
                    spellcheck: false,
                    value: data.firstName,
                    onInput: handleInputFor('firstName'),
                    onChange: handleChangeFor('firstName')
                })),
                h(Field, { label: i18n.get('lastName'), classNameModifiers: ['lastName', 'col-50'], errorMessage: errors.lastName }, renderFormField('text', {
                    name: 'lastName',
                    autocorrect: 'off',
                    spellcheck: false,
                    value: data.lastName,
                    onInput: handleInputFor('lastName'),
                    onChange: handleChangeFor('lastName')
                })),
                h(Field, { label: "" + i18n.get('boleto.socialSecurityNumber'), classNameModifiers: ['socialSecurityNumber'], errorMessage: errors.socialSecurityNumber, isValid: Boolean(valid.socialSecurityNumber) }, renderFormField('text', {
                    name: 'socialSecurityNumber',
                    autocorrect: 'off',
                    spellcheck: false,
                    value: data.socialSecurityNumber,
                    onInput: handleCPFInput,
                    maxLength: 18,
                    onChange: handleChangeFor('socialSecurityNumber')
                }))))),
        props.billingAddressRequired && (h(Address, { label: "billingAddress", data: __assign(__assign({}, data.billingAddress), { country: 'BR' }), onChange: handleAddress, requiredFields: ['street', 'houseNumberOrName', 'postalCode', 'city', 'stateOrProvince'], ref: addressRef })),
        props.showEmailAddress && (h(SendCopyToEmail, { value: data.shopperEmail, errors: errors.shopperEmail, onToggle: toggleEmailField, onInput: handleInputFor('shopperEmail'), onChange: handleChangeFor('shopperEmail') })),
        props.showPayButton && props.payButton({ label: i18n.get('boletobancario.btnLabel'), classNameModifiers: buttonModifiers })));
}
BoletoInput.defaultProps = {
    data: {},
    showEmailAddress: true,
    personalDetailsRequired: true,
    billingAddressRequired: true
};
export default BoletoInput;
