import { __assign, __extends } from "tslib";
import { CardElement } from './Card';
var BancontactElement = /** @class */ (function (_super) {
    __extends(BancontactElement, _super);
    function BancontactElement(props) {
        var _this = _super.call(this, props) || this;
        // Disable internal event.emit() for Bancontact
        _this.onBrand = function (event) {
            if (_this.props.onBrand)
                _this.props.onBrand(event);
        };
        return _this;
    }
    BancontactElement.prototype.formatProps = function (props) {
        return __assign(__assign({}, _super.prototype.formatProps.call(this, props)), { 
            // Override only display brands (groupTypes are decided earlier on super.formatProps)
            brands: ['bcmc', 'maestro'] });
    };
    return BancontactElement;
}(CardElement));
export default BancontactElement;
