import { __assign, __extends } from "tslib";
import { Component, h } from 'preact';
import handlers from './handlers';
import SecuredFieldsProvider from './SecuredFieldsProvider';
var SecuredFields = /** @class */ (function (_super) {
    __extends(SecuredFields, _super);
    function SecuredFields(props) {
        var _this = _super.call(this, props) || this;
        _this.handleSecuredFieldsRef = function (ref) {
            _this.sfp = ref;
        };
        _this.handleSecuredFieldsChange = function (sfpState) {
            _this.setState(__assign(__assign({}, sfpState), { isValid: sfpState.isSfpValid }));
        };
        // Add as a prop when declaring SecuredFieldsProvider
        _this.handleOnError = handlers.handleOnError.bind(_this);
        return _this;
    }
    SecuredFields.prototype.componentDidMount = function () {
        this.setFocusOn = this.sfp.setFocusOn;
        this.updateStyles = this.sfp.updateStyles;
        this.showValidation = this.sfp.showValidation;
        this.processBinLookupResponse = this.sfp.processBinLookupResponse;
    };
    SecuredFields.prototype.componentDidUpdate = function () {
        this.props.onChange(this.state);
    };
    SecuredFields.prototype.componentWillUnmount = function () {
        this.sfp.destroy();
        this.sfp = null;
    };
    SecuredFields.prototype.getChildContext = function () {
        return { i18n: this.props.i18n };
    };
    SecuredFields.prototype.render = function () {
        return (h(SecuredFieldsProvider, __assign({ ref: this.handleSecuredFieldsRef }, this.props, { onChange: this.handleSecuredFieldsChange, onError: this.handleOnError, render: function () { return null; } })));
    };
    SecuredFields.defaultProps = {
        onChange: function () { },
        onError: function () { }
    };
    return SecuredFields;
}(Component));
export default SecuredFields;
