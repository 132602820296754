import { __rest } from "tslib";
import { h } from 'preact';
import CVC from './CVC';
import Field from '~/components/internal/FormFields/Field';
var OneClickCardInput = function (_a, _b) {
    var i18n = _b.i18n;
    var brand = _a.brand, hasCVC = _a.hasCVC, onFocusField = _a.onFocusField, errors = _a.errors, valid = _a.valid, props = __rest(_a, ["brand", "hasCVC", "onFocusField", "errors", "valid"]);
    // TODO create a translation key for "stored card ends in"
    var ariaLabel = "stored card ends in " + props.lastFour + "\n        " + i18n.get('creditCard.expiryDateField.title') + " " + props.expiryMonth + "/" + props.expiryYear;
    return (h("div", { className: "adyen-checkout__card__form adyen-checkout__card__form--oneClick", "aria-label": ariaLabel },
        h("div", { className: "adyen-checkout__card__exp-cvc adyen-checkout__field-wrapper" },
            h(Field, { label: i18n.get('creditCard.expiryDateField.title'), className: "adyen-checkout__field--50", classNameModifiers: ['storedCard'], disabled: true },
                h("div", { className: "adyen-checkout__input adyen-checkout__input--disabled adyen-checkout__card__exp-date__input--oneclick" },
                    props.expiryMonth,
                    " / ",
                    props.expiryYear)),
            hasCVC && (h(CVC, { cvcRequired: props.cvcRequired, error: !!errors.encryptedSecurityCode, focused: props.focusedElement === 'encryptedSecurityCode', filled: !!valid.encryptedSecurityCode || !!errors.encryptedSecurityCode, hideCVCForBrand: props.hideCVCForBrand, isValid: !!valid.encryptedSecurityCode, label: i18n.get('creditCard.cvcField.title'), onFocusField: onFocusField, className: 'adyen-checkout__field--50', classNameModifiers: ['storedCard'], frontCVC: brand === 'amex' })))));
};
export default OneClickCardInput;
