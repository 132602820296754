import { __assign } from "tslib";
import createElements from './createElements';
/**
 *  Returns a filtered (available) list of oneClick paymentMethod Elements
 * @param {Array} paymentMethods
 * @param {Object} props Props to be passed through to every paymentMethod
 * @param {Object} paymentMethodsConfig Specific config per payment method (where key is the name of the paymentMethod)
 */
var createStoredElements = function (paymentMethods, props, paymentMethodsConfig) {
    if (paymentMethods === void 0) { paymentMethods = []; }
    if (paymentMethodsConfig === void 0) { paymentMethodsConfig = {}; }
    return createElements(paymentMethods, __assign(__assign({}, props), { oneClick: true }), paymentMethodsConfig);
};
export default createStoredElements;
