import { __assign, __rest } from "tslib";
import { FALLBACK_CONTEXT } from '~/core/config';
var returnImage = function (_a) {
    var name = _a.name, loadingContext = _a.loadingContext, _b = _a.imageFolder, imageFolder = _b === void 0 ? '' : _b, _c = _a.parentFolder, parentFolder = _c === void 0 ? '' : _c, extension = _a.extension, _d = _a.size, size = _d === void 0 ? '' : _d, _e = _a.subFolder, subFolder = _e === void 0 ? '' : _e;
    return loadingContext + "images/" + imageFolder + subFolder + parentFolder + name + size + "." + extension;
};
export var getImageUrl = function (_a) {
    var _b = _a.loadingContext, loadingContext = _b === void 0 ? FALLBACK_CONTEXT : _b, _c = _a.extension, extension = _c === void 0 ? 'svg' : _c, _d = _a.size, size = _d === void 0 ? '3x' : _d, options = __rest(_a, ["loadingContext", "extension", "size"]);
    return function (name) {
        var imageOptions = __assign({ extension: extension,
            loadingContext: loadingContext, imageFolder: 'logos/', parentFolder: '', name: name }, options);
        if (extension !== 'svg') {
            return returnImage(__assign({ size: "@" + size, subFolder: 'small/' }, imageOptions));
        }
        return returnImage(imageOptions);
    };
};
export default getImageUrl;
