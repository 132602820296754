import { h } from 'preact';
import { useState, useLayoutEffect } from 'preact/hooks';
import { renderFormField } from '../../FormFields';
import Field from '../../FormFields/Field';
import useCoreContext from '~/core/Context/useCoreContext';
import fetchJSONData from '~/utils/fetch-json-data';
import { COUNTRIES_WITH_STATES_DATASET } from '../constants';
export default function StateField(props) {
    var country = props.country, onDropdownChange = props.onDropdownChange, value = props.value, readOnly = props.readOnly;
    var _a = useCoreContext(), i18n = _a.i18n, loadingContext = _a.loadingContext;
    var _b = useState([]), states = _b[0], setStates = _b[1];
    var _c = useState(false), loaded = _c[0], setLoaded = _c[1];
    useLayoutEffect(function () {
        if (!country || !COUNTRIES_WITH_STATES_DATASET.includes(country)) {
            setStates([]);
            setLoaded(true);
            return;
        }
        fetchJSONData({
            path: "datasets/states/" + country + "/" + useCoreContext().locale + ".json",
            loadingContext: loadingContext
        })
            .then(function (response) {
            var newStates = response && response.length ? response : [];
            setStates(newStates);
            setLoaded(true);
        })
            .catch(function () {
            setStates([]);
            setLoaded(true);
        });
    }, [country]);
    if (!loaded || !states.length)
        return null;
    return (h(Field, { label: i18n.get('stateOrProvince'), classNameModifiers: ['stateOrProvince'], errorMessage: props.errorMessage }, renderFormField('select', {
        name: 'stateOrProvince',
        onChange: onDropdownChange,
        selected: value,
        placeholder: i18n.get('select.stateOrProvince'),
        items: states,
        readonly: readOnly && !!value
    })));
}
