import { __assign, __extends } from "tslib";
import { Component, h } from 'preact';
import Get3DS2DeviceFingerprint from './Get3DS2DeviceFingerprint';
import { createResolveData, encodeResult, handleErrorCode, prepareFingerPrintData } from '../utils';
var ThreeDS2DeviceFingerprint = /** @class */ (function (_super) {
    __extends(ThreeDS2DeviceFingerprint, _super);
    function ThreeDS2DeviceFingerprint(props) {
        var _this = _super.call(this, props) || this;
        if (_this.props.fingerprintToken) {
            var fingerPrintData = prepareFingerPrintData({
                fingerPrintToken: _this.props.fingerprintToken,
                notificationURL: _this.props.notificationURL
            });
            _this.state = {
                status: 'init',
                fingerPrintData: fingerPrintData
            };
        }
        else {
            _this.state = { status: 'error' };
            _this.props.onError('Missing fingerprintToken parameter');
        }
        return _this;
    }
    ThreeDS2DeviceFingerprint.prototype.componentDidMount = function () {
        // If no fingerPrintData, don't even bother
        if (!this.state.fingerPrintData) {
            this.setStatusComplete({ threeDSCompInd: 'U' });
            return;
        }
        // If no methodURL - don't render component. Instead exit with threeDSCompInd: 'U'
        if (!this.state.fingerPrintData.methodURL || !this.state.fingerPrintData.methodURL.length) {
            this.setStatusComplete({ threeDSCompInd: 'U' });
            return;
        }
        // Render
        this.setState({ status: 'retrievingFingerPrint' });
    };
    ThreeDS2DeviceFingerprint.prototype.setStatusComplete = function (resultObj) {
        var _this = this;
        this.setState({ status: 'complete' }, function () {
            var paymentData = _this.props.paymentData;
            var result = encodeResult(resultObj, _this.props.type);
            var data = createResolveData(_this.props.dataKey, result, paymentData);
            _this.props.onComplete(data);
        });
    };
    ThreeDS2DeviceFingerprint.prototype.render = function (props, _a) {
        var _this = this;
        var fingerPrintData = _a.fingerPrintData;
        if (this.state.status === 'retrievingFingerPrint') {
            return (h(Get3DS2DeviceFingerprint, __assign({ onCompleteFingerprint: function (fingerprint) {
                    _this.setStatusComplete(fingerprint.result);
                }, onErrorFingerprint: function (fingerprint) {
                    var errorObject = handleErrorCode(fingerprint.errorCode);
                    _this.props.onError(errorObject);
                    _this.setStatusComplete(fingerprint.result);
                }, showSpinner: this.props.showSpinner }, fingerPrintData)));
        }
        return null;
    };
    ThreeDS2DeviceFingerprint.type = 'scheme';
    ThreeDS2DeviceFingerprint.defaultProps = {
        onComplete: function () { },
        onError: function () { },
        paymentData: '',
        showSpinner: true
    };
    return ThreeDS2DeviceFingerprint;
}(Component));
export default ThreeDS2DeviceFingerprint;
