import { __assign, __rest, __spreadArrays } from "tslib";
import { h } from 'preact';
import Button from '../Button';
import useCoreContext from '~/core/Context/useCoreContext';
var PayButton = function (_a) {
    var _b = _a.amount, amount = _b === void 0 ? {} : _b, _c = _a.classNameModifiers, classNameModifiers = _c === void 0 ? [] : _c, label = _a.label, props = __rest(_a, ["amount", "classNameModifiers", "label"]);
    var i18n = useCoreContext().i18n;
    var isZeroAuth = amount && {}.hasOwnProperty.call(amount, 'value') && amount.value === 0;
    var defaultLabel = isZeroAuth
        ? i18n.get('confirmPreauthorization')
        : i18n.get('payButton') + " " + (!!amount.value && !!amount.currency ? i18n.amount(amount.value, amount.currency) : '');
    return h(Button, __assign({}, props, { classNameModifiers: __spreadArrays(classNameModifiers, ['pay']), i18n: i18n, label: label || defaultLabel }));
};
export default PayButton;
