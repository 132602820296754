import { __rest } from "tslib";
import { useState, useEffect } from 'preact/hooks';
import { h } from 'preact';
import { renderFormField } from '../FormFields';
import useCoreContext from '~/core/Context/useCoreContext';
/**
 * "Store details" generic checkbox
 */
function StoreDetails(_a) {
    var _b = _a.storeDetails, storeDetails = _b === void 0 ? false : _b, props = __rest(_a, ["storeDetails"]);
    var i18n = useCoreContext().i18n;
    var _c = useState(storeDetails), value = _c[0], setValue = _c[1];
    var onChange = function (e) {
        setValue(e.target.checked);
    };
    useEffect(function () {
        props.onChange(value);
    }, [value]);
    return (h("div", { className: "adyen-checkout__store-details" }, renderFormField('boolean', {
        onChange: onChange,
        label: i18n.get('storeDetails'),
        value: value,
        name: 'storeDetails'
    })));
}
export default StoreDetails;
