import { getTranslatedErrors } from './utils';
/**
 * Handles validation errors
 */
function handleOnError(e) {
    var errorTranslations = getTranslatedErrors(this.props.i18n);
    if (e.error.length) {
        // Take advantage of the built-in i18n to add a translated error message in a .i18n property
        e.i18n = errorTranslations[e.fieldType];
    }
    this.props.onError(e);
}
export default {
    handleOnError: handleOnError
};
