import { __spreadArrays } from "tslib";
import classNames from 'classnames';
import { h } from 'preact';
import Field from '~/components/internal/FormFields/Field';
import styles from '../CardInput.module.scss';
import CVCHint from './CVCHint';
var CVC = function (_a, _b) {
    var _c, _d;
    var label = _a.label, _e = _a.onFocusField, onFocusField = _e === void 0 ? function () { } : _e, _f = _a.error, error = _f === void 0 ? false : _f, _g = _a.className, className = _g === void 0 ? '' : _g, _h = _a.classNameModifiers, classNameModifiers = _h === void 0 ? [] : _h, focused = _a.focused, filled = _a.filled, isValid = _a.isValid, _j = _a.frontCVC, frontCVC = _j === void 0 ? false : _j, _k = _a.hideCVCForBrand, hideCVCForBrand = _k === void 0 ? false : _k, _l = _a.cvcRequired, cvcRequired = _l === void 0 ? true : _l;
    var i18n = _b.i18n;
    var fieldClassnames = classNames(className, (_c = {
            'adyen-checkout__field__cvc': true
        },
        _c[styles['adyen-checkout__card__cvc__input--hidden']] = hideCVCForBrand,
        _c['adyen-checkout__field__cvc--optional'] = !cvcRequired,
        _c));
    var cvcClassnames = classNames((_d = {
            'adyen-checkout__input': true,
            'adyen-checkout__input--small': true,
            'adyen-checkout__card__cvc__input': true,
            'adyen-checkout__input--error': error,
            'adyen-checkout__input--focus': focused,
            'adyen-checkout__input--valid': isValid
        },
        _d[styles['adyen-checkout__input']] = true,
        _d));
    var fieldLabel = cvcRequired ? label : i18n.get('creditCard.cvcField.title.optional');
    return (h(Field, { label: fieldLabel, focused: focused, filled: filled, classNameModifiers: __spreadArrays(classNameModifiers, ['securityCode']), onFocusField: function () { return onFocusField('encryptedSecurityCode'); }, className: fieldClassnames, errorMessage: !!error && i18n.get('creditCard.oneClickVerification.invalidInput.title'), isValid: isValid },
        h("span", { className: cvcClassnames, "data-cse": "encryptedSecurityCode" }),
        h(CVCHint, { frontCVC: frontCVC })));
};
export default CVC;
