import { FALLBACK_CONTEXT } from '../config';
/**
 * Submits data to Adyen
 * @param {string} initiationUrl Url where to make the callbacks
 * @param {object} data ready to be serialized and included in the body of request
 * @return {Promise} a promise containing the response of the call
 */
var getStatus = function (initiationUrl, data) {
    var options = {
        method: 'POST',
        headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return fetch(initiationUrl, options)
        .then(function (response) { return response.json(); })
        .catch(function (error) {
        throw error;
    });
};
/**
 * Submits data to Adyen
 * @param {string} paymentData
 * @param {string} originKey
 * @param {string} loadingContext
 * @return {Promise} a promise containing the response of the call
 */
export var checkPaymentStatus = function (paymentData, accessKey, loadingContext) {
    if (!paymentData || !accessKey) {
        throw new Error('Could not check the payment status');
    }
    var statusUrl = (loadingContext || FALLBACK_CONTEXT) + "services/PaymentInitiation/v1/status?token=" + accessKey;
    return getStatus(statusUrl, { paymentData: paymentData });
};
export default {
    checkPaymentStatus: checkPaymentStatus
};
