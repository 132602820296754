import { __rest } from "tslib";
import classNames from 'classnames';
import { h } from 'preact';
import styles from '../CardInput.module.scss';
import BrandIcon from './BrandIcon';
import Field from '~/components/internal/FormFields/Field';
import useCoreContext from '~/core/Context/useCoreContext';
var CardNumber = function (_a) {
    var _b;
    var _c = _a.error, error = _c === void 0 ? false : _c, _d = _a.isValid, isValid = _d === void 0 ? false : _d, _e = _a.onFocusField, onFocusField = _e === void 0 ? function () { } : _e, props = __rest(_a, ["error", "isValid", "onFocusField"]);
    var _f = useCoreContext(), i18n = _f.i18n, loadingContext = _f.loadingContext;
    return (h(Field, { label: props.label, focused: props.focused, filled: props.filled, classNameModifiers: ['cardNumber'], onFocusField: function () { return onFocusField('encryptedCardNumber'); }, errorMessage: error && i18n.get('creditCard.numberField.invalid'), isValid: isValid },
        h("span", { "data-cse": "encryptedCardNumber", className: classNames((_b = {
                    'adyen-checkout__input': true,
                    'adyen-checkout__input--large': true,
                    'adyen-checkout__card__cardNumber__input': true
                },
                _b[styles['adyen-checkout__input']] = true,
                _b['adyen-checkout__input--error'] = error,
                _b['adyen-checkout__input--focus'] = props.focused,
                _b['adyen-checkout__input--valid'] = isValid,
                _b['adyen-checkout__card__cardNumber__input--noBrand'] = !props.showBrandIcon,
                _b)) }, props.showBrandIcon && h(BrandIcon, { brand: props.brand, loadingContext: loadingContext }))));
};
export default CardNumber;
