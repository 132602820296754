import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { checkPaymentStatus } from '~/core/Services/payment-status';
import processResponse from '~/core/ProcessResponse/process-response';
import { getImageUrl } from '~/utils/get-image';
import './Await.scss';
import Spinner from '~/components/internal/Spinner';
import Countdown from '~/components/internal/Countdown';
import Button from '~/components/internal/Button';
import useCoreContext from '~/core/Context/useCoreContext';
function Await(props) {
    var _this = this;
    var i18n = useCoreContext().i18n;
    var _a = useState(false), completed = _a[0], setCompleted = _a[1];
    var _b = useState(false), expired = _b[0], setExpired = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(props.delay), delay = _d[0], setDelay = _d[1];
    var _e = useState(100), percentage = _e[0], setPercentage = _e[1];
    var _f = useState(0), timePassed = _f[0], setTimePassed = _f[1];
    var _g = useState(false), hasAdjustedTime = _g[0], setHasAdjustedTime = _g[1];
    var _h = useState(null), storedTimeout = _h[0], setStoredTimeout = _h[1];
    var onTimeUp = function () {
        setExpired(true);
        clearTimeout(storedTimeout);
        props.onError({ type: 'error', props: { errorMessage: 'Payment Expired' } });
    };
    var onTick = function (time) {
        setPercentage(time.percentage);
    };
    var onComplete = function (status) {
        setCompleted(true);
        props.onComplete({
            data: {
                details: { payload: status.props.payload },
                paymentData: props.paymentData
            }
        }, _this);
        return status;
    };
    var onError = function (status) {
        setExpired(true);
        props.onError({
            status: status,
            data: {
                details: { payload: status.props.payload },
                paymentData: props.paymentData
            }
        });
        return status;
    };
    var checkStatus = function () {
        var paymentData = props.paymentData, originKey = props.originKey, clientKey = props.clientKey, loadingContext = props.loadingContext;
        var accessKey = clientKey ? clientKey : originKey;
        return checkPaymentStatus(paymentData, accessKey, loadingContext)
            .then(processResponse)
            .catch(function (response) { return ({ type: 'network-error', props: response }); })
            .then(function (status) {
            switch (status.type) {
                case 'success':
                    return onComplete(status);
                case 'error':
                    return onError(status);
                default:
                    setLoading(false);
            }
            return status;
        });
    };
    var redirectToApp = function (url, fallback) {
        if (fallback === void 0) { fallback = function () { }; }
        setTimeout(function () {
            // Redirect to the APP failed
            props.onError(props.type + " App was not found");
            fallback();
        }, 25);
        window.location.assign(url);
    };
    // didMount & willUnmount
    useEffect(function () {
        var shouldRedirectOnMobile = props.shouldRedirectOnMobile, url = props.url;
        var isMobile = window.matchMedia('(max-width: 768px)').matches && /Android|iPhone|iPod/.test(navigator.userAgent);
        if (shouldRedirectOnMobile && url && isMobile) {
            _this.redirectToApp(url, checkStatus);
        }
        else {
            checkStatus();
        }
        return function () {
            clearTimeout(storedTimeout);
        };
    }, []);
    // didUpdate
    useEffect(function () {
        // --
        if (expired) {
            clearTimeout(storedTimeout);
            return;
        }
        if (completed) {
            clearTimeout(storedTimeout);
            return;
        }
        if (!loading) {
            // Retry until getting a complete response from the server OR it times out
            // Changes setTimeout time to new value (throttleInterval) after a certain amount of time (throttleTime) has passed
            var statusInterval = function () {
                checkStatus();
                var actualTimePassed = timePassed + delay;
                setTimePassed(actualTimePassed);
                if (actualTimePassed >= props.throttleTime && !hasAdjustedTime) {
                    setDelay(props.throttleInterval);
                    //                    console.log('### AwaitHooks::statusInterval:: setting delay=', props.throttleInterval);
                    setHasAdjustedTime(true);
                }
            };
            setStoredTimeout(setTimeout(statusInterval, delay));
        }
    }, [loading, timePassed, expired, completed]);
    var finalState = function (image, message) { return (h("div", { className: "adyen-checkout__await adyen-checkout__await--result" },
        h("img", { className: "adyen-checkout__await__icon adyen-checkout__await__icon--result", src: getImageUrl({ loadingContext: props.loadingContext, imageFolder: 'components/' })(image), alt: i18n.get(message) }),
        h("div", { className: "adyen-checkout__await__subtitle adyen-checkout__await__subtitle--result" }, i18n.get(message)))); };
    if (expired) {
        return finalState('error', 'error.subtitle.payment');
    }
    if (completed) {
        return finalState('success', 'creditCard.success');
    }
    if (loading) {
        return (h("div", { className: "adyen-checkout__await" },
            props.brandLogo && h("img", { src: props.brandLogo, className: "adyen-checkout__await__brand-logo" }),
            h(Spinner, { inline: false, size: "large" })));
    }
    var timeToPayString = i18n.get('wechatpay.timetopay').split('%@');
    return (h("div", { className: "\n                    adyen-checkout__await\n                    adyen-checkout__await--" + props.type + "\n                    " + props.classNameModifiers.map(function (m) { return "adyen-checkout__await--" + m; }) + "\n                " },
        props.brandLogo && h("img", { src: props.brandLogo, alt: props.type, className: "adyen-checkout__await__brand-logo" }),
        h("div", { className: "adyen-checkout__await__subtitle" }, props.messageText),
        h("div", { className: "adyen-checkout__await__indicator-holder" },
            h("div", { className: "adyen-checkout__await__indicator-spinner" },
                h(Spinner, { inline: false, size: "medium" })),
            h("div", { className: "adyen-checkout__await__indicator-text" }, props.awaitText)),
        props.showCountdownTimer && (h("div", { className: "adyen-checkout__await__countdown-holder" },
            h("div", { className: "adyen-checkout__await__progress" },
                h("span", { className: "adyen-checkout__await__percentage", style: { width: percentage + "%" } })),
            h("div", { className: "adyen-checkout__await__countdown" },
                timeToPayString[0],
                "\u00A0",
                h(Countdown, { minutesFromNow: props.countdownTime, onTick: onTick, onCompleted: onTimeUp }),
                "\u00A0",
                timeToPayString[1]))),
        props.url && (h("div", { className: "adyen-checkout__await__app-link" },
            h("span", { className: "adyen-checkout__await__separator__label" }, i18n.get('or')),
            h(Button, { classNameModifiers: ['await'], onClick: function () { return redirectToApp(props.url); }, i18n: i18n, label: i18n.get('openApp') })))));
}
Await.defaultProps = {
    countdownTime: 15,
    onError: function () { },
    onComplete: function () { },
    throttleTime: 60000,
    throttleInterval: 10000,
    showCountdownTimer: true,
    classNameModifiers: [],
    shouldRedirectOnMobile: false,
    url: null
};
export default Await;
