var checkedAtob = window.atob;
var checkedBtoa = window.btoa;
var b64DecodeUnicode = function (str) {
    /**
     * The "Unicode Problem" Since DOMStrings are 16-bit-encoded strings:
     * In most browsers calling window.btoa on a Unicode string will cause
     * a Character Out Of Range exception if a character exceeds the range
     * of a 8-bit ASCII-encoded character.
     * This method solves the problem
     */
    return decodeURIComponent(Array.prototype.map.call(checkedAtob(str), function (c) { return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2); }).join(''));
};
/**
 * @private
 */
var base64 = {
    decode: function (pData) {
        if (!base64.isBase64(pData)) {
            return false;
        }
        if (base64.isBase64(pData)) {
            return b64DecodeUnicode(pData);
        }
        return false;
    },
    encode: function (pData) { return checkedBtoa(pData); },
    isBase64: function (pDataStr) {
        if (!pDataStr) {
            return false;
        }
        if (pDataStr.length % 4) {
            return false;
        }
        return checkedBtoa(checkedAtob(pDataStr)) === pDataStr;
    }
};
export default base64;
