import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import QRLoader from '../internal/QRLoader';
import CoreProvider from '../../core/Context/CoreProvider';
var QRCODE_URL = 'barcode.shtml?barcodeType=qrCode&fileType=png&data=';
/**
 * QRLoaderContainer: A higher order function which returns a different class based on issuerType
 * @extends UIElement
 */
var withQRLoader = function (_a) {
    var type = _a.type, _b = _a.brandLogo, brandLogo = _b === void 0 ? null : _b, _c = _a.buttonLabel, buttonLabel = _c === void 0 ? null : _c, STATUS_INTERVAL = _a.STATUS_INTERVAL, COUNTDOWN_MINUTES = _a.COUNTDOWN_MINUTES, _d = _a.shouldRedirectOnMobile, shouldRedirectOnMobile = _d === void 0 ? false : _d;
    var QRLoaderContainer = /** @class */ (function (_super) {
        __extends(QRLoaderContainer, _super);
        function QRLoaderContainer() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        /**
         * Formats props on construction time
         * @return {object} props
         */
        QRLoaderContainer.prototype.formatProps = function (props) {
            var qrCodeImage = props.qrCodeData ? "" + props.loadingContext + QRCODE_URL + props.qrCodeData : props.qrCodeImage;
            return __assign(__assign({}, props), { qrCodeImage: qrCodeImage });
        };
        /**
         * Formats the component data output
         * @return {object} props
         */
        QRLoaderContainer.prototype.formatData = function () {
            return {
                paymentMethod: __assign({ type: this.props.type || QRLoaderContainer.type }, this.state.data)
            };
        };
        Object.defineProperty(QRLoaderContainer.prototype, "isValid", {
            /**
             * Returns whether the component state is valid or not
             * @return {boolean} isValid
             */
            get: function () {
                return true;
            },
            enumerable: false,
            configurable: true
        });
        QRLoaderContainer.prototype.render = function () {
            var _this = this;
            if (this.props.paymentData) {
                return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
                    h(QRLoader, __assign({ ref: function (ref) {
                            _this.componentRef = ref;
                        } }, this.props, { shouldRedirectOnMobile: shouldRedirectOnMobile, type: QRLoaderContainer.type, brandLogo: brandLogo || this.icon, delay: STATUS_INTERVAL, onComplete: this.onComplete, countdownTime: COUNTDOWN_MINUTES }))));
            }
            if (this.props.showPayButton) {
                return this.payButton({
                    label: buttonLabel ? this.props.i18n.get(buttonLabel) : this.props.i18n.get('continue'),
                    classNameModifiers: ['standalone']
                });
            }
            return null;
        };
        QRLoaderContainer.type = type;
        QRLoaderContainer.defaultProps = {
            qrCodeImage: '',
            amount: null,
            paymentData: null,
            onError: function () { },
            onComplete: function () { }
        };
        return QRLoaderContainer;
    }(UIElement));
    return QRLoaderContainer;
};
export default withQRLoader;
