import { __assign } from "tslib";
import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import classNames from 'classnames';
import AchSecuredFields from './components/AchSecuredFields';
import SecuredFieldsProvider from '~/components/internal/SecuredFields/SecuredFieldsProvider';
import Address from '~/components/internal/Address';
import { renderFormField } from '~/components/internal/FormFields';
import Field from '~/components/internal/FormFields/Field';
import LoadingWrapper from '~/components/internal/LoadingWrapper/LoadingWrapper';
import defaultProps from './defaultProps';
import defaultStyles from './defaultStyles';
import useCoreContext from '~/core/Context/useCoreContext';
import styles from './AchInput.module.scss';
import './AchInput.scss';
function validateHolderName(holderName, holderNameRequired) {
    if (holderNameRequired) {
        return !!holderName && typeof holderName === 'string' && holderName.trim().length > 0;
    }
    return true;
}
function AchInput(props) {
    var _this = this;
    var i18n = useCoreContext().i18n;
    var holderNameIsValid = props.hasHolderName && (props.holderName || props.data.holderName);
    var _a = useState({}), errors = _a[0], setErrors = _a[1];
    var _b = useState(__assign({}, (props.holderNameRequired && { holderName: holderNameIsValid }))), valid = _b[0], setValid = _b[1];
    var _c = useState(__assign({}, (props.hasHolderName && { holderName: props.holderName || props.data.holderName }))), data = _c[0], setData = _c[1];
    var _d = useState(props.billingAddressRequired ? props.data.billingAddress : null), billingAddress = _d[0], setBillingAddress = _d[1];
    var _e = useState(false), isSfpValid = _e[0], setIsSfpValid = _e[1];
    var _f = useState(''), focusedElement = _f[0], setFocusedElement = _f[1];
    var handleFocus = function (e) {
        var isFocused = e.focus === true;
        setFocusedElement(e.currentFocusObject);
        if (isFocused) {
            props.onFocus(e);
        }
        else {
            props.onBlur(e);
        }
    };
    var handleAddress = function (address) {
        setBillingAddress(__assign(__assign({}, billingAddress), address.data));
        setValid(__assign(__assign({}, valid), { billingAddress: address.isValid }));
    };
    var handleHolderName = function (e) {
        var holderName = e.target.value;
        setData(__assign(__assign({}, data), { holderName: holderName }));
        setErrors(__assign(__assign({}, errors), { holderName: props.holderNameRequired ? !validateHolderName(holderName) : false }));
        setValid(__assign(__assign({}, valid), { holderName: props.holderNameRequired ? validateHolderName(holderName, props.holderNameRequired) : true }));
    };
    var handleSecuredFieldsChange = function (newState) {
        var sfState = newState;
        var tempHolderName = sfState.autoCompleteName ? sfState.autoCompleteName : data.holderName;
        setData(__assign(__assign(__assign({}, data), sfState.data), { holderName: tempHolderName }));
        setErrors(__assign(__assign({}, errors), sfState.errors));
        setValid(__assign(__assign(__assign({}, valid), sfState.valid), { holderName: props.holderNameRequired ? validateHolderName(tempHolderName, props.holderNameRequired) : true }));
        setIsSfpValid(sfState.isSfpValid);
    };
    // Refs
    var sfp = useRef(null);
    var billingAddressRef = useRef(null);
    this.showValidation = function () {
        // Validate SecuredFields
        sfp.current.showValidation();
        // Validate holderName
        if (props.holderNameRequired && !valid.holderName) {
            setErrors(__assign(__assign({}, errors), { holderName: true }));
        }
        // Validate Address
        if (billingAddressRef.current)
            billingAddressRef.current.showValidation();
    };
    useEffect(function () {
        _this.setFocusOn = sfp.current.setFocusOn;
        _this.updateStyles = sfp.current.updateStyles;
        return function () {
            sfp.current.destroy();
        };
    }, []);
    // Run when state.data, -errors or -valid change
    useEffect(function () {
        // Validate whole component i.e holderName + securedFields + address
        var holderNameValid = validateHolderName(data.holderName, props.holderNameRequired);
        var sfpValid = isSfpValid;
        var billingAddressValid = props.billingAddressRequired ? Boolean(valid.billingAddress) : true;
        var isValid = sfpValid && holderNameValid && billingAddressValid;
        props.onChange({ data: data, isValid: isValid, billingAddress: billingAddress });
    }, [data, valid, errors]);
    return (h("div", { className: "adyen-checkout__ach" },
        h(SecuredFieldsProvider, __assign({ ref: sfp }, props, { styles: __assign(__assign({}, defaultStyles), props.styles), onChange: handleSecuredFieldsChange, onFocus: handleFocus, render: function (_a, sfpState) {
                var setRootNode = _a.setRootNode, setFocusOn = _a.setFocusOn;
                return (h("div", { ref: setRootNode, className: "adyen-checkout__ach-input " + styles['sf-input__wrapper'] },
                    h(LoadingWrapper, { status: sfpState.status },
                        h("div", { className: classNames(['adyen-checkout__fieldset', 'adyen-checkout__fieldset--ach']) },
                            h("div", { className: "adyen-checkout__fieldset__title" }, i18n.get('ach.bankAccount')),
                            props.hasHolderName && (h(Field, { label: i18n.get('ach.accountHolderNameField.title'), className: 'adyen-checkout__pm__holderName', errorMessage: !!errors.holderName && i18n.get('ach.accountHolderNameField.invalid'), isValid: !!valid.holderName }, renderFormField('text', {
                                className: "adyen-checkout__pm__holderName__input " + styles['adyen-checkout__input'],
                                placeholder: props.placeholders.holderName || i18n.get('ach.accountHolderNameField.placeholder'),
                                value: data.holderName,
                                required: props.holderNameRequired,
                                onInput: handleHolderName
                            }))),
                            h(AchSecuredFields, { focusedElement: focusedElement, onFocusField: setFocusOn, errors: sfpState.errors, valid: sfpState.valid })),
                        props.billingAddressRequired && (h(Address, { i18n: i18n, loadingContext: props.loadingContext, label: "billingAddress", data: billingAddress, onChange: handleAddress, allowedCountries: props.billingAddressAllowedCountries, requiredFields: props.billingAddressRequiredFields, ref: billingAddressRef })))));
            } })),
        props.showPayButton && props.payButton({ status: 'ready', label: i18n.get('confirmPurchase') })));
}
AchInput.defaultProps = defaultProps;
export default AchInput;
