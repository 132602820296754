import { __extends } from "tslib";
import { Component, h } from 'preact';
var Redirect = /** @class */ (function (_super) {
    __extends(Redirect, _super);
    function Redirect() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Redirect.prototype.componentDidMount = function () {
        var _this = this;
        var doRedirect = function () {
            if (_this.postForm) {
                _this.postForm.submit();
            }
            else {
                window.location.assign(_this.props.url);
            }
        };
        var dispatchEvent = new Promise(function (resolve, reject) { return _this.props.beforeRedirect(resolve, reject, _this.props.url); });
        dispatchEvent.then(doRedirect).catch(function () { });
    };
    Redirect.prototype.render = function (_a) {
        var _this = this;
        var url = _a.url, method = _a.method, data = _a.data;
        if (method === 'POST') {
            return (h("form", { method: "post", action: url, style: { display: 'none' }, ref: function (ref) {
                    _this.postForm = ref;
                } }, Object.keys(data).map(function (key) { return (h("input", { type: "hidden", name: key, key: key, value: data[key] })); })));
        }
        return null;
    };
    Redirect.defaultProps = {
        beforeRedirect: function (resolve) { return resolve(); },
        method: 'GET',
        data: {}
    };
    return Redirect;
}(Component));
export default Redirect;
