import getProp from './getProp';
/**
 * @function collectBrowserInfo
 *
 * @desc Collects available frontend browser info and store it in the properties dictated by the EMVCo spec
 * (3DS_Spec_protocolAndCoreFny_v2-1_Oct2017.pdf)
 *
 * @example const browserInfo = collectBrowserInfo();
 * const userAgent = browserInfo.userAgent;
 *
 * @returns {Object} - browserInfo an object containing the retrieved browser properties
 */
export default function collectBrowserInfo() {
    var colorDepth = getProp(window, 'screen.colorDepth') || '';
    var javaEnabled = getProp(window, 'navigator.javaEnabled') ? window.navigator.javaEnabled() : false;
    var screenHeight = getProp(window, 'screen.height') || ''; // TODO: Shall we set this to null instead?
    var screenWidth = getProp(window, 'screen.width') || ''; // TODO: Shall we set this to null instead?
    var userAgent = getProp(window, 'navigator.userAgent') || '';
    // IE <+ 10 supports navigator.browserLanguage instead of navigator.language
    var language = getProp(window, 'navigator.language') || getProp(window, 'navigator.browserLanguage');
    var d = new Date();
    var timeZoneOffset = d.getTimezoneOffset();
    return {
        acceptHeader: '*/*',
        colorDepth: colorDepth,
        language: language,
        javaEnabled: javaEnabled,
        screenHeight: screenHeight,
        screenWidth: screenWidth,
        userAgent: userAgent,
        timeZoneOffset: timeZoneOffset
    };
}
