import { h } from 'preact';
import useCoreContext from '~/core/Context/useCoreContext';
import classNames from 'classnames';
import './DisableOneClickConfirmation.scss';
var DisableOneClickConfirmation = function (_a) {
    var id = _a.id, open = _a.open, onDisable = _a.onDisable, onCancel = _a.onCancel;
    var i18n = useCoreContext().i18n;
    return (h("div", { id: id, "aria-hidden": !open, className: classNames({
            'adyen-checkout__payment-method__disable-confirmation': true,
            'adyen-checkout__payment-method__disable-confirmation--open': open
        }) },
        h("div", { className: "adyen-checkout__payment-method__disable-confirmation__content" },
            i18n.get('storedPaymentMethod.disable.confirmation'),
            h("div", { className: "adyen-checkout__payment-method__disable-confirmation__buttons" },
                h("button", { className: classNames('adyen-checkout__button', 'adyen-checkout__payment-method__disable-confirmation__button', 'adyen-checkout__payment-method__disable-confirmation__button--remove'), disabled: !open, onClick: onDisable }, i18n.get('storedPaymentMethod.disable.confirmButton')),
                h("button", { className: classNames('adyen-checkout__button', 'adyen-checkout__payment-method__disable-confirmation__button', 'adyen-checkout__payment-method__disable-confirmation__button--cancel'), disabled: !open, onClick: onCancel }, i18n.get('storedPaymentMethod.disable.cancelButton'))))));
};
export default DisableOneClickConfirmation;
