import { FALLBACK_CONTEXT } from '~/core/config';
export default (function (_a, dataObj) {
    var path = _a.path, _b = _a.loadingContext, loadingContext = _b === void 0 ? FALLBACK_CONTEXT : _b, _c = _a.method, method = _c === void 0 ? 'GET' : _c, _d = _a.contentType, contentType = _d === void 0 ? 'text/plain' : _d;
    var options = {
        method: method,
        mode: 'cors',
        cache: 'default',
        credentials: 'same-origin',
        headers: {
            Accept: 'application/json',
            'Content-Type': contentType
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer-when-downgrade',
        body: JSON.stringify(dataObj)
    };
    var url = "" + loadingContext + path;
    return fetch(url, options)
        .then(function (response) {
        if (response.ok)
            return response.json();
        return console.warn("Service at " + url + " is not available");
    })
        .then(function (data) { return data; })
        .catch(function (e) {
        console.warn("Call to " + url + " failed. Error= " + e);
    });
});
