var defaultProps = {
    environment: 'TEST',
    status: 'loading',
    // Config
    merchantId: '',
    style: {
        height: 48
    },
    // Events
    onSubmit: function () { },
    onAdditionalDetails: function () { },
    onInit: function () { },
    onClick: function () { },
    onCancel: function () { },
    onError: function () { }
};
export default defaultProps;
