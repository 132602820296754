import { __assign } from "tslib";
import { ADYEN_CLIENTID_TEST, ADYEN_CLIENTID_LIVE, INTEGRATION_DATE, PAYPAL_JS_URL } from './config';
var getPaypalSettings = function (_a) {
    var amount = _a.amount, countryCode = _a.countryCode, _b = _a.environment, environment = _b === void 0 ? '' : _b, intent = _a.intent, locale = _a.locale, merchantId = _a.merchantId;
    var shopperLocale = locale ? locale.replace('-', '_') : null;
    var currency = amount ? amount.currency : null;
    var isTestEnvironment = environment.toLowerCase() === 'test';
    var clientId = isTestEnvironment ? ADYEN_CLIENTID_TEST : ADYEN_CLIENTID_LIVE;
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (merchantId && { 'merchant-id': merchantId })), (shopperLocale && { locale: shopperLocale })), (countryCode && isTestEnvironment && { 'buyer-country': countryCode })), (currency && { currency: currency })), (intent && { intent: intent })), { 'client-id': clientId, 'integration-date': INTEGRATION_DATE, components: 'buttons,funding-eligibility' });
};
var getPayPalParams = function (props) {
    var settings = getPaypalSettings(props);
    var params = [];
    Object.keys(settings).forEach(function (name) {
        var value = decodeURIComponent(settings[name]);
        params.push(name + "=" + value);
    });
    return params.join('&');
};
var getPaypalUrl = function (props) {
    var params = getPayPalParams(props);
    return PAYPAL_JS_URL + "?" + params;
};
export { getPaypalSettings, getPaypalUrl };
