import { __assign, __extends } from "tslib";
import { Component, h } from 'preact';
import classNames from 'classnames';
import Field from '../FormFields/Field';
import { renderFormField } from '../FormFields';
import { validatePhoneNumber } from './validate';
var PhoneInput = /** @class */ (function (_super) {
    __extends(PhoneInput, _super);
    function PhoneInput(props) {
        var _this = _super.call(this, props) || this;
        _this.showValidation = function () {
            var isPrefixValid = _this.props.items && _this.props.items.length ? !!_this.state.data.phonePrefix : true;
            var isPhoneNumberValid = validatePhoneNumber(_this.state.data.phoneNumber, _this.props.minLength);
            _this.setState({
                errors: {
                    phoneNumber: !isPhoneNumberValid,
                    phonePrefix: !isPrefixValid
                }
            });
        };
        _this.handlePrefixChange = _this.handlePrefixChange.bind(_this);
        _this.handlePhoneInput = _this.handlePhoneInput.bind(_this);
        _this.onChange = _this.onChange.bind(_this);
        _this.state = {
            data: {
                phonePrefix: _this.props.selected,
                phoneNumber: ''
            },
            errors: {}
        };
        return _this;
    }
    PhoneInput.prototype.onChange = function () {
        var _this = this;
        var isPrefixValid = this.props.items ? !!this.state.data.phonePrefix : true;
        var isPhoneNumberValid = validatePhoneNumber(this.state.data.phoneNumber, this.props.minLength);
        var isValid = isPrefixValid && isPhoneNumberValid;
        this.setState({ isValid: isValid }, function () {
            _this.props.onChange(_this.state);
        });
    };
    PhoneInput.prototype.handlePhoneInput = function (e) {
        e.preventDefault();
        var phoneNumber = e.target.value;
        var isPhoneNumberValid = validatePhoneNumber(phoneNumber, this.props.minLength);
        this.setState(function (prevState) { return ({
            data: __assign(__assign({}, prevState.data), { phoneNumber: phoneNumber }),
            errors: __assign(__assign({}, prevState.errors), { phoneNumber: !isPhoneNumberValid })
        }); }, this.onChange);
    };
    PhoneInput.prototype.handlePrefixChange = function (e) {
        e.preventDefault();
        var phonePrefix = e.currentTarget.getAttribute('data-value');
        var isPrefixValid = !!phonePrefix;
        this.setState(function (prevState) { return (__assign({ data: __assign(__assign({}, prevState.data), { phonePrefix: phonePrefix }) }, (isPrefixValid && { errors: __assign(__assign({}, prevState.errors), { phonePrefix: false }) }))); }, this.onChange);
    };
    PhoneInput.prototype.render = function (_a) {
        var items = _a.items, i18n = _a.i18n;
        var showPrefix = !!items && items.length;
        return (h("div", { className: "adyen-checkout__phone-input" },
            h("div", { className: "adyen-checkout__phone-input__container adyen-checkout__field-group" },
                !!showPrefix && (h(Field, { errorMessage: !!this.state.errors.phonePrefix, label: i18n.get('infix'), className: classNames({
                        'adyen-checkout__phone-input__prefix': true,
                        'adyen-checkout__field--col-30': true
                    }) }, renderFormField('select', {
                    className: 'adyen-checkout__dropdown--small',
                    items: items,
                    name: this.props.prefixName,
                    onChange: this.handlePrefixChange,
                    placeholder: i18n.get('infix'),
                    selected: this.state.data.phonePrefix
                }))),
                h(Field, { errorMessage: !!this.state.errors.phoneNumber, label: i18n.get('telephoneNumber'), className: classNames({
                        'adyen-checkout__input--phone-number': true,
                        'adyen-checkout__field--col-70': showPrefix
                    }) },
                    h("input", { type: "tel", name: this.props.phoneName, value: this.state.data.phoneNumber, onInput: this.handlePhoneInput, placeholder: "123 456 789", className: "adyen-checkout__input", autoCorrect: "off", spellCheck: false }))),
            this.props.showPayButton && this.props.payButton()));
    };
    PhoneInput.defaultProps = {
        onChange: function () { },
        onValid: function () { },
        phoneName: 'phoneNumber',
        prefixName: 'phonePrefix',
        selected: null,
        minLength: 3
    };
    return PhoneInput;
}(Component));
export default PhoneInput;
