import { __extends, __spreadArrays } from "tslib";
import { Component, h } from 'preact';
import cx from 'classnames';
import styles from './Select.module.scss';
import './Select.scss';
var SelectBox = /** @class */ (function (_super) {
    __extends(SelectBox, _super);
    function SelectBox(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            toggleDropdown: false
        };
        _this.toggle = _this.toggle.bind(_this);
        _this.select = _this.select.bind(_this);
        _this.closeDropdown = _this.closeDropdown.bind(_this);
        _this.handleButtonKeyDown = _this.handleButtonKeyDown.bind(_this);
        _this.handleClickOutside = _this.handleClickOutside.bind(_this);
        _this.handleKeyDown = _this.handleKeyDown.bind(_this);
        _this.handleOnError = _this.handleOnError.bind(_this);
        return _this;
    }
    SelectBox.prototype.componentDidMount = function () {
        document.addEventListener('click', this.handleClickOutside, false);
    };
    SelectBox.prototype.componentWillUnmount = function () {
        document.removeEventListener('click', this.handleClickOutside, false);
    };
    SelectBox.prototype.handleClickOutside = function (e) {
        if (!this.selectContainer.contains(e.target)) {
            this.setState({ toggleDropdown: false });
        }
    };
    SelectBox.prototype.toggle = function (e) {
        e.preventDefault();
        this.setState({ toggleDropdown: !this.state.toggleDropdown });
    };
    SelectBox.prototype.select = function (e) {
        e.preventDefault();
        this.closeDropdown();
        this.props.onChange(e);
    };
    /**
     * Closes the dropdown and focuses the button element
     */
    SelectBox.prototype.closeDropdown = function () {
        var _this = this;
        this.setState({ toggleDropdown: false }, function () { return _this.toggleButton.focus(); });
    };
    /**
     * Handle keyDown events on the adyen-checkout__dropdown__element
     * Navigates through the list, or select an element, or close the menu.
     * @param {Object} e KeyDownEvent
     */
    SelectBox.prototype.handleKeyDown = function (e) {
        switch (e.key) {
            case 'Escape':
                e.preventDefault();
                this.setState({ toggleDropdown: false });
                break;
            case ' ': // space
            case 'Enter':
                this.select(e);
                break;
            case 'ArrowDown':
                e.preventDefault();
                if (e.target.nextElementSibling)
                    e.target.nextElementSibling.focus();
                break;
            case 'ArrowUp':
                e.preventDefault();
                if (e.target.previousElementSibling)
                    e.target.previousElementSibling.focus();
                break;
            default:
        }
    };
    /**
     * Handle keyDown events on the adyen-checkout__dropdown__button
     * Opens the dropdownList and focuses the first element if available
     * @param {Object} e KeyDownEvent
     */
    SelectBox.prototype.handleButtonKeyDown = function (e) {
        switch (e.key) {
            case 'ArrowUp':
            case 'ArrowDown':
            case ' ': // space
            case 'Enter':
                e.preventDefault();
                this.setState({ toggleDropdown: true });
                if (this.dropdownList && this.dropdownList.firstElementChild) {
                    this.dropdownList.firstElementChild.focus();
                }
                break;
            default:
        }
    };
    SelectBox.prototype.handleOnError = function (e) {
        e.target.style.cssText = 'display: none';
    };
    SelectBox.prototype.render = function (_a, _b) {
        var _c, _d;
        var _this = this;
        var _e = _a.className, className = _e === void 0 ? '' : _e, _f = _a.classNameModifiers, classNameModifiers = _f === void 0 ? [] : _f, isInvalid = _a.isInvalid, _g = _a.items, items = _g === void 0 ? [] : _g, placeholder = _a.placeholder, readonly = _a.readonly, selected = _a.selected;
        var toggleDropdown = _b.toggleDropdown;
        var active = items.find(function (i) { return i.id === selected; }) || {};
        return (h("div", { className: cx(__spreadArrays([
                'adyen-checkout__dropdown',
                styles['adyen-checkout__dropdown'],
                className
            ], classNameModifiers.map(function (m) { return "adyen-checkout__dropdown--" + m; }))), ref: function (ref) {
                _this.selectContainer = ref;
            } },
            h("button", { type: "button", className: cx([
                    'adyen-checkout__dropdown__button',
                    styles['adyen-checkout__dropdown__button'],
                    (_c = {
                            'adyen-checkout__dropdown__button--readonly': readonly,
                            'adyen-checkout__dropdown__button--active': toggleDropdown
                        },
                        _c[styles['adyen-checkout__dropdown__button--active']] = toggleDropdown,
                        _c['adyen-checkout__dropdown__button--invalid'] = isInvalid,
                        _c)
                ]), onClick: !readonly ? this.toggle : undefined, onKeyDown: !readonly ? this.handleButtonKeyDown : undefined, tabIndex: "0", title: active.name || placeholder, "aria-haspopup": "listbox", "aria-expanded": toggleDropdown, "aria-readonly": readonly, ref: function (ref) {
                    _this.toggleButton = ref;
                } },
                h("span", { className: "adyen-checkout__dropdown__button__text" }, active.name || placeholder),
                active.icon && (h("img", { className: "adyen-checkout__dropdown__button__icon", src: active.icon, alt: active.name, onError: this.handleOnError }))),
            h("ul", { role: "listbox", className: cx((_d = {
                        'adyen-checkout__dropdown__list': true
                    },
                    _d[styles['adyen-checkout__dropdown__list']] = true,
                    _d['adyen-checkout__dropdown__list--active'] = toggleDropdown,
                    _d[styles['adyen-checkout__dropdown__list--active']] = toggleDropdown,
                    _d)), ref: function (ref) {
                    _this.dropdownList = ref;
                } }, items.map(function (item) { return (h("li", { key: item.id, role: "option", tabIndex: "-1", "aria-selected": item.id === active.id, className: cx([
                    'adyen-checkout__dropdown__element',
                    styles['adyen-checkout__dropdown__element'],
                    { 'adyen-checkout__dropdown__element--active': item.id === active.id }
                ]), "data-value": item.id, onClick: _this.select, onKeyDown: _this.handleKeyDown },
                h("span", null, item.name),
                item.icon && (h("img", { className: "adyen-checkout__dropdown__element__icon", alt: item.name, src: item.icon, onError: _this.handleOnError })))); }))));
    };
    SelectBox.defaultProps = {
        items: [],
        readonly: false,
        onChange: function () { }
    };
    return SelectBox;
}(Component));
export default SelectBox;
