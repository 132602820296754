import { __assign, __spreadArrays } from "tslib";
import defaultTranslation from './locales/en-US.json';
import { formatCustomTranslations, formatLocale, getTranslation, loadTranslations, parseLocale } from './utils';
import { FALLBACK_LOCALE } from './config';
import locales from './locales';
import { getLocalisedAmount } from '~/utils/amount-util';
var Language = /** @class */ (function () {
    function Language(locale, customTranslations) {
        if (locale === void 0) { locale = FALLBACK_LOCALE; }
        if (customTranslations === void 0) { customTranslations = {}; }
        this.translations = defaultTranslation;
        var defaultLocales = Object.keys(locales);
        this.customTranslations = formatCustomTranslations(customTranslations, defaultLocales);
        var localesFromCustomTranslations = Object.keys(this.customTranslations);
        this.supportedLocales = __spreadArrays(defaultLocales, localesFromCustomTranslations).filter(function (v, i, a) { return a.indexOf(v) === i; }); // our locales + validated custom locales
        this.locale = formatLocale(locale) || parseLocale(locale, this.supportedLocales) || FALLBACK_LOCALE;
        this.translations = loadTranslations(this.locale, this.customTranslations);
    }
    /**
     * Returns a translated string from a key and a locale
     * @param {string} key
     * @return {string}
     */
    Language.prototype.get = function (key) {
        var translation = getTranslation(this.translations, key);
        if (translation !== null) {
            return translation;
        }
        return key;
    };
    /**
     * Returns a localized string for an amount
     * @param {number} amount
     * @param {string} currencyCode
     * @param {object} options
     * @return {string}
     */
    Language.prototype.amount = function (amount, currencyCode, options) {
        return getLocalisedAmount(amount, this.locale, currencyCode, options);
    };
    /**
     * Returns a localized string for a date
     * @param {string} date
     * @param {object} options for [Date.prototype.toLocaleDateString](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString)
     * @return {string}
     */
    Language.prototype.date = function (date, options) {
        if (options === void 0) { options = {}; }
        var dateOptions = __assign({ year: 'numeric', month: '2-digit', day: '2-digit' }, options);
        return new Date(date).toLocaleDateString(this.locale, dateOptions);
    };
    return Language;
}());
export { Language };
export default Language;
