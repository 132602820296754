import { __assign } from "tslib";
import { h } from 'preact';
import classNames from 'classnames';
export default function InputBase(props) {
    var isInvalid = props.isInvalid, isValid = props.isValid, classNameModifiers = props.classNameModifiers, readonly = props.readonly, spellCheck = props.spellCheck, type = props.type, validation = props.validation;
    var inputClassNames = classNames('adyen-checkout__input', ["adyen-checkout__input--" + type], props.className, {
        'adyen-checkout__input--invalid': isInvalid,
        'adyen-checkout__input--valid': isValid
    }, classNameModifiers.map(function (m) { return "adyen-checkout__input--" + m; }));
    return (h("input", __assign({}, props, validation, { type: type, className: inputClassNames, readOnly: readonly || null, spellCheck: spellCheck, autoCorrect: spellCheck })));
}
InputBase.defaultProps = {
    type: 'text',
    className: '',
    classNameModifiers: [],
    validation: {}
};
