import { __extends } from "tslib";
import { Component, h } from 'preact';
var SelectListItem = /** @class */ (function (_super) {
    __extends(SelectListItem, _super);
    function SelectListItem(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClick = _this.handleClick.bind(_this);
        return _this;
    }
    SelectListItem.prototype.handleClick = function (e) {
        e.preventDefault();
        this.props.onChange(this.props.item);
    };
    SelectListItem.prototype.render = function (_a) {
        var item = _a.item, selected = _a.selected;
        var className = "adyen-checkout__select-list__item " + (selected ? 'adyen-checkout__select-list__item--selected' : '');
        return (h("li", { className: className, onClick: this.handleClick }, item.displayName));
    };
    return SelectListItem;
}(Component));
export default SelectListItem;
