export function normalizeAmount(props) {
    if (typeof props.amount === 'object' && {}.hasOwnProperty.call(props.amount, 'value')) {
        return props.amount.value;
    }
    return props.amount;
}
/**
 * @private
 * Gets a currencyCode from an amount object or defaults to a currencyCode property.
 */
export function normalizeCurrency(props) {
    if (typeof props.amount === 'object' && {}.hasOwnProperty.call(props.amount, 'currency')) {
        return props.amount.currency;
    }
    return props.currencyCode;
}
