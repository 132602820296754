import classNames from 'classnames';
import { h } from 'preact';
import Field from '~/components/internal/FormFields/Field';
import styles from '../CardInput.module.scss';
var ExpirationDate = function (_a, _b) {
    var _c;
    var label = _a.label, focused = _a.focused, filled = _a.filled, onFocusField = _a.onFocusField, _d = _a.className, className = _d === void 0 ? '' : _d, _e = _a.error, error = _e === void 0 ? false : _e, _f = _a.isValid, isValid = _f === void 0 ? false : _f;
    var i18n = _b.i18n;
    return (h(Field, { label: label, classNameModifiers: ['expiryDate'], className: className, focused: focused, filled: filled, onFocusField: function () { return onFocusField('encryptedExpiryDate'); }, errorMessage: error && i18n.get('creditCard.expiryDateField.invalid'), isValid: isValid },
        h("span", { "data-cse": "encryptedExpiryDate", className: classNames((_c = {
                    'adyen-checkout__input': true,
                    'adyen-checkout__input--small': true,
                    'adyen-checkout__card__exp-date__input': true
                },
                _c[styles['adyen-checkout__input']] = true,
                _c['adyen-checkout__input--error'] = error,
                _c['adyen-checkout__input--focus'] = focused,
                _c['adyen-checkout__input--valid'] = isValid,
                _c)) })));
};
export default ExpirationDate;
