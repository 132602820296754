import { h } from 'preact';
import classNames from 'classnames';
import Spinner from '~/components/internal/Spinner';
import styles from './LoadingWrapper.module.scss';
var LoadingWrapper = function (_a) {
    var _b, _c;
    var children = _a.children, status = _a.status;
    var wrapperClass = classNames('adyen-checkout__loading-input__form', styles['loading-input__form'], (_b = {},
        _b[styles['loading-input__form--loading']] = status === 'loading',
        _b));
    var spinnerClass = classNames((_c = {},
        _c[styles['loading-input__spinner']] = true,
        _c[styles['loading-input__spinner--active']] = status === 'loading',
        _c));
    return (h("div", { style: { position: 'relative' } },
        h("div", { className: spinnerClass },
            h(Spinner, null)),
        h("div", { className: wrapperClass }, children)));
};
export default LoadingWrapper;
